/* eslint-disable react-hooks/rules-of-hooks */
import { CONSTANTS } from "@constants/index";
import { useTheme } from "../hooks/theming"
import { FileType } from "@types";
import { useParams } from "react-router-dom";
import { location } from '@utils';

export const loadGlobalServices = () => {

    const { hotsite, campaign } = useTheme();
    const params = useParams();
    console.log('params in', params);

    const aliasCompany = CONSTANTS.ALIAS.COMPANY(location.getSubdomain() as string);
    const aliasProgramCode = CONSTANTS.ALIAS.PROGRAM_CODE(location.getFirstPathname() as string);
    const aliasCampaign = CONSTANTS.ALIAS.CAMPAIGN(params.campaignPath as string);

    const loadThemeObjectProps = {
        websiteAliasCompany: aliasCompany,
        websiteAliasProgram: aliasProgramCode,
    }
    const loadHotsiteTheme = (loadType: FileType[]) => {
        if (loadType.includes('styles') && !hotsite.theme.texts.isLoaded) {
            hotsite.loadTheme({
                ...loadThemeObjectProps,
                fileType: 'styles',
            });
        }
        if (loadType.includes('texts') && !hotsite.theme.styles.isLoaded) {
            hotsite.loadTheme({
                ...loadThemeObjectProps,
                fileType: 'texts',
            });
        }
    }

    const loadCampaignTheme = (loadType: FileType[]) => {
        if (loadType.includes('styles') && !campaign.theme.styles.isLoaded) {
            campaign.loadTheme({
                ...loadThemeObjectProps,
                websiteAliasCampaign: aliasCampaign,
                fileType: 'styles',
            });
        }

        if (loadType.includes('texts') && !campaign.theme.texts.isLoaded) {
            campaign.loadTheme({
                ...loadThemeObjectProps,
                websiteAliasCampaign: aliasCampaign,
                fileType: 'texts',
            });
        }
    }

    return {
        theme: {
            loadHotsiteTheme,
            loadCampaignTheme,
        }
    }
}