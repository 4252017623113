import {
    ListCampaignProps,
    LoadCampaignRegulationProps,
    LoadThemeFilesProps,
    TalkToUsCampaignProps,
} from "@interfaces/CampaignServicesNOVO";
import { client } from "../../client";

export const loadThemeFiles = async ({ websiteAliasCompany, websiteAliasProgram, websiteAliasCampaign, fileType }: LoadThemeFilesProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('FILES');
    const file = `${fileType}.json`;
    try {
        const response = await instance.get(`${websiteAliasCompany}/${websiteAliasProgram}/${websiteAliasCampaign}/${file}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadCampaigns = async ({ websiteAliasCompany, websiteAliasProgram }: ListCampaignProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('CAMPAIGN');
    try {
        const response = await instance.get(`/company/${websiteAliasCompany}/program/${websiteAliasProgram}/campaigns`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
}


export const loadCampaignRegulation = async ({ websiteAliasCompany, websiteAliasProgram, websiteAliasCampaign }: LoadCampaignRegulationProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('CAMPAIGN');
    try {
        const response = await instance.get(`/company/${websiteAliasCompany}/program/${websiteAliasProgram}/regulation${websiteAliasCampaign ? '?websiteAliasCampaign='+websiteAliasCampaign : ''}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
}

// export const listLoyaltyPartner = async ({ websiteAliasCampaign }: ListCampaignProps) => {
//     const { handleErrors, handleResponse, handleInstance } = client();
//     const instance = handleInstance('CAMPAIGN');
//     try {
//         const response = await instance.get(`/campaign/loyalty?websiteAlias=${websiteAliasCampaign}`);
//         return handleResponse(response);
//     } catch (error: any) {
//         return handleErrors(error);
//     }
// }

export const talkToUs = async ({ websiteAliasCompany, websiteAliasProgram, websiteAliasCampaign, data }: TalkToUsCampaignProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('CAMPAIGN');
    try {
        const response = await instance.post(`/talkToUs/company/${websiteAliasCompany}/program/${websiteAliasProgram}${websiteAliasCampaign ? '?websiteAliasCampaign=' + websiteAliasCampaign : ''}`, data);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
}