import React from 'react';

import { Main } from './styles';

interface HeadlineProps {
    className: string;
    color: string;
    children: React.ReactNode;
}

const Headline: React.FC<HeadlineProps> = ({ className, children, color }: HeadlineProps) => {
    return (
        <Main
            color={color}
            className={className}>
            {children}
        </Main>
    )
}

export { Headline };