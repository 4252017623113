/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom"
import React from "react";
import { loadGlobalServices } from '@load/global-services';

export const DefaultLayout: React.FC = () => {
    const globalServices = loadGlobalServices();

    React.useEffect(() => {
        globalServices.theme.loadHotsiteTheme(['texts', 'styles']);
        globalServices.theme.loadCampaignTheme(['texts', 'styles']);
    }, []);

    return (
        <div className="wrapper">
            <Outlet />
        </div>
    )
}