import React from "react";
import {HowWorksBox} from '@design-system';
import {Main, Text, Title, Subtitle, WrapperSubtitle} from './styles';

interface HowWorksProps {
    step: number;
    color: string;
    title: string;
    subtitle: string;
    styleBox: {
        boxColor: string;
        labelColor: string;
    }
}

const HowWorks: React.FC<HowWorksProps> =
  ({ step, color, title, subtitle, styleBox }: HowWorksProps) => {

    return (
        <Main className="howWorksMain">
            <HowWorksBox
              style={styleBox}
              step={step}>
                <Text className="textDiv">
                    <Title
                        color={color}
                        className="titleColor">{title}</Title>
                    <WrapperSubtitle>
                        <Subtitle
                            className="subtitleColor">{subtitle}</Subtitle>

                    </WrapperSubtitle>
                </Text>
            </HowWorksBox>
        </Main>
    )
}

export {HowWorks};