import React from 'react';
import { CampaignContext } from './context';
import { useCampaignProvider } from '../../hooks/campaign';
import { ProviderProps } from '@interfaces/Contexts';


const CampaignProvider = ({ children }: ProviderProps) => {
    const value = useCampaignProvider();

    return (
        <CampaignContext.Provider value={value}>
            {children}
        </CampaignContext.Provider>
    );
};

export {
    CampaignProvider,
};
