import { ErrorDataProps, ErrorsDataPayloadProps } from '@interfaces/ClientServicesApi';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import configs from '@config/config.json';
import * as auth from './apis/auth';
import * as hotsite from './apis/hotsite';
import * as campaign from './apis/campaign';
import * as participant from './apis/participant';
import * as app from './apis/app';
import { CONSTANTS } from '../constants';
import { EndPointTypes } from 'src/types';

const client = () => {
    const services = {
        auth,
        hotsite,
        campaign,
        participant,
        app,
    };
    const { DOMAINS, ENDPOINTS } = CONSTANTS.SERVICES_APIS_DOMAINS;

    const handleInstance = (endpoint?: EndPointTypes): AxiosInstance => {
        let apiURL = "";
        if (endpoint) {
            if (configs.serverURL.indexOf(DOMAINS.LOCAL) !== -1) {
                apiURL = DOMAINS.LOCAL + ENDPOINTS.LOCAL[endpoint];
            }
            if (configs.serverURL.indexOf(DOMAINS.H1) !== -1) {
                apiURL = configs.serverURL + ENDPOINTS.H1[endpoint];
            }
            if (configs.serverURL.indexOf(DOMAINS.D1) !== -1) {
                apiURL = configs.serverURL + ENDPOINTS.D1[endpoint];
            }
            if (configs.serverURL.indexOf(DOMAINS.P1) !== -1) {
                apiURL = configs.serverURL + ENDPOINTS.P1[endpoint];
            }
        } else {
            apiURL = configs.serverURL;
        }

        const instance = axios.create({
            baseURL: apiURL,// configs.serverURL,
            timeout: 120000,
            withCredentials: true,
        });
        return instance;
    }

    const handleResponse = (response: AxiosResponse) => {
        const res = {
            ...response.data,
            status: response.status,
        };
        return {
            error: false,
            response: res,
        };
    };

    const handleErrors = (error: any) => {
        if (error instanceof AxiosError) {
            const response = {
                ...error.response?.data as ErrorDataProps | ErrorsDataPayloadProps,
                status: error.response?.status,
            };

            return {
                error: true,
                response,
            };
        }
    };

    return {
        services,
        handleErrors,
        handleResponse,
        handleInstance,
    };
};


export { client };
