/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { AuthContext } from "../../contexts/auth/context";

import { usePreRegister } from './pre-register';

export const authProvider = () => {
    const preRegister = usePreRegister();
    return {
        preRegister,
    }
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    return context;
};
