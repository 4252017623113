import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/styles/index.scss";
import "./assets/styles/1-base/bootstrap-grid.min.css";
import "./libs/fontawesome";
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);
