import styled from "styled-components";

import {theme} from '@theme';

export const Main = styled.div`
    height: 100%;
    ${theme.breakpoints.down('lg', `
        padding-bottom: 15px;
        height: 100%;
    `)}
`;

export const BoxContainer= styled.div`
    width: 100%;
    height: 100%;
    border-radius: ${theme.border.default};
    display: flex;
`;

interface BoxIconProps {
    iconBackgroundColor: string;
    iconColor: string;
}

export const BoxIcon = styled.div<BoxIconProps>`
    display: flex;
    background-color: ${props => props.iconBackgroundColor};
    justify-content: center;
    align-items: center;
    min-width: 80px;
    border-radius: ${theme.border.default} 0 0 ${theme.border.default};

    & > svg {
        color:  ${props => props.iconColor};
    }
`;

export const ImageBox = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
`
