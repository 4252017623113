import { AccessTypesProps, CreateParticipantFormDataProps, PreRegisterListKeysProps, PreRegisterValidateKeysProps, PreRegisteredParticipantInfoProps, SendParticipantFormDataProps } from '@interfaces/AuthService';
import { client } from '../../client';

const loadKeys = async ({ subdomain }: PreRegisterListKeysProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('CAMPAIGN');
    try {
        const response = await instance.get(`/campaign/${subdomain}/preRegister/keys`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const registerTypeInformation = async ({ websiteAliasCompany, websiteAliasProgram }: AccessTypesProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('CAMPAIGN');
    try {
        const response = await instance.get(`/company/${websiteAliasCompany}/program/${websiteAliasProgram}/registration/info`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const validateKeys = async ({ subdomain, data }: PreRegisterValidateKeysProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('USER');

    try {
        const response = await instance.post(`/preRegistration/campaign/${subdomain}/validate/accessKeys`, {
            accessKeys: data,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadPreRegisteredParticipantInfo = async ({ token }: PreRegisteredParticipantInfoProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('USER');
    try {
        const response = await instance.get(`/preRegistration/load/${token}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const sendParticipantFormData = async ({ token, data }: SendParticipantFormDataProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('USER');
    try {
        const response = await instance.post(`/preRegistration/finish/${token}`, {
            data
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const createCampaignParticipant = async ({ subdomain, data }: CreateParticipantFormDataProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('USER');
    try {
        const response = await instance.post(`/participant/campaing/${subdomain}/create`, {
            data
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const requestNewEmail = async () => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('USER');
    try {
        const response = await instance.post(`/validation/requestNewEmail`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    loadKeys,
    validateKeys,
    loadPreRegisteredParticipantInfo,
    sendParticipantFormData,
    requestNewEmail,
    createCampaignParticipant,
    registerTypeInformation,
};
