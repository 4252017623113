import styled from 'styled-components';

import { theme } from '@theme';

const { border, color, typography, breakpoints } = theme;

export const Main = styled.button`
    padding: 0;
    border: 0;
    background-color: ${color.common.white};
    cursor: pointer;
    width: 100%;
    border: 1px solid ${color.grey[200]};
    border-radius: ${border.default};
    padding: 0 0;
    margin-bottom: 1.3125rem;
`;

export const Columns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Column = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Count = styled.h3`
    font-size: 24px;
    font-weight: ${typography.fontWeightBold};
    color: ${(props) => props.color};
    font-family: Arial, Helvetica, sans-serif;
    padding: 16px 23px;
`;

export const Title = styled.h1`
    font-size: 1rem;
    font-weight: ${typography.fontWeightMedium};
    color: ${color.grey[900]};
    text-align: left;
    padding: 1.0938rem 0;
`;

export const ToggleIcon = styled.div`
    padding: 0 17px;
`;

export const Content = styled.div`
    background-color: ${color.grey[50]};
    border-top: 1px solid ${color.grey[200]};
    // padding: 25px 54px 25px 54px;

    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease;
    box-sizing: border-box;

    
    &.active {
        visibility: visible;
        transition: all 0.3s ease;
        max-height: 1000px;
    }

    &.inactive {
        visibility: hidden;
        transition: all 0s ease;
        max-height: 0;
    }
`;

export const Text = styled.div`
    text-align: left;
    padding: 25px 25px;
    font-size: 14px;
    font-weight: ${typography.fontWeightLight};
    color: ${color.grey[900]};

    ${breakpoints.down('md', `
        padding: 15px;
    `)}
`;
