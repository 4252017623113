import styled from 'styled-components';

import { theme } from '@theme';

const { border, color, typography, shadow } = theme;

export const Main = styled.div`
max-width: 268px;
background-color: ${color.common.white};
  border-radius: ${border.default};
  border: .0625rem solid ${color.grey[200]};
  -webkit-transition: all 0.24s ease-in-out;
    -moz-transition: all 0.24s ease-in-out;
    -ms-transition: all 0.24s ease-in-out;
    -o-transition: all 0.24s ease-in-out;
    transition: all 0.24s ease-in-out;

    &:hover {
        box-shadow: ${shadow.default};
        -webkit-transition: all 0.24s ease-in-out;
        -moz-transition: all 0.24s ease-in-out;
        -ms-transition: all 0.24s ease-in-out;
        -o-transition: all 0.24s ease-in-out;
        transition: all 0.24s ease-in-out;
    }
`;

export const CardHead = styled.div`
  overflow: hidden;
  border-top-left-radius: ${border.default};
  border-top-right-radius: ${border.default};
  border-bottom: .0625rem solid ${color.grey[200]};;
`;

export const LogoCampaign = styled.img`
    position: absolute;
    margin: 8px;
    max-height: 26px;
    max-width: 80px;
    object-fit: contain;
`;

export const CardMedia = styled.div`
  position: relative;
  min-width: 246px;
  width: 100%;
  
  & > .WrapAnchorPicture {
            height: 100vh;
            max-height: 10rem;

            & > .AnchorPicture {
                display: block;
                height: 100%;
                width: 100%;
                overflow: hidden;

                & > .WrapPicture {
                    height: 100%;
                    width: 100%;
                    picture {
                        source,
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border: 0;
                            max-width: 100%;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
`;

export const CardContent = styled.div`
    padding: .9375rem;
    height: 11.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Title = styled.h2`
    font-size: 1.125rem;
    font-weight: ${typography.fontWeightBold};
    color: ${color.grey[900]};
    word-break: break-word;
`;

export const Description = styled.p`
    font-size: .8125rem;
    font-weight: ${typography.fontWeightRegular};
    color: ${color.grey[600]};
    margin: 0;
    height: 25px;
    margin-top: .625rem;
    word-break: break-all;
`;