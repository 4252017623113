import React from "react";
import { location } from "@utils";

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import {Main, BoxContainer, BoxIcon, ImageBox} from './styles';

interface HowWorksBoxCampaignProps {
    iconData: IconProp;
    srcImage: string
    children: React.ReactNode;
    sx: {
        iconBackgroundColor: string;
        iconColor: string;
    }
}

const HowWorksBoxCampaign: React.FC<HowWorksBoxCampaignProps> =
  ({srcImage, sx, children}: HowWorksBoxCampaignProps) => {
    return (
        <Main className="HowWorksBoxCampaign">
            <BoxContainer className="boxContainer">
                <BoxIcon
                    iconBackgroundColor={sx.iconBackgroundColor}
                    iconColor={sx.iconColor}
                    className="boxIcon">
                    <ImageBox src={`${location.filesPath(srcImage)}`} alt="" />
                </BoxIcon>
                {children}
            </BoxContainer>
        </Main>
    )
}

export {HowWorksBoxCampaign};