import React from "react";
import { LinkStyled, Image } from './styles';

interface LogoIconProps {
    src: string;
    link: string;
    className?: string;
}

const LogoIcon: React.FC<LogoIconProps> = ({ src, link, className }: LogoIconProps) => {
    return (
        <LinkStyled onClick={() => {
            window.scrollTo({ top: 0})
        }} className={className ? className : ''} to={link} >
            <Image src={src} alt="Home logo" />
        </LinkStyled>
    )
}

export { LogoIcon };