import { ActiveCampaignsProps } from "@interfaces/CampaignNOVO";

const RESPONSE = {
    isLoaded: false,
    status: 0,
    message: '',
};

export const DEFAULTS = {
    CAMPAIGN: {
        CAMPAIGNS_REGULANTION: {
            ...RESPONSE,
            data: '',
        },
        LIST_CAMPAIGNS: {
            ...RESPONSE,
            data: [] as ActiveCampaignsProps[],
        }
    }
};