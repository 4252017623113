import React from "react";
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {Main, Button, ArrowDiv, Text} from './styles';
import { Link } from "react-router-dom";

interface ParticipateButtonProps {
    link: string;
    style: {
        bgColor: string;
        color: string;
    };
}

const ParticipateButton: React.FC<ParticipateButtonProps> =
  ({link, style}: ParticipateButtonProps) => {
    // const url = window.location.hostname;
    
    return (
        <Main className="participateButton">
            <Link reloadDocument to={link} className="anchor">
                <Button
                    bgColor={style.bgColor}
                    color={style.color}
                    className="buttonElement">
                    <Text>
                        Participar
                    </Text>
                    <ArrowDiv className="arrow">
                        <I
                        className="arrowIcon" 
                        icon={['far', 'chevron-right']}
                        style={{
                            color: style.bgColor,
                            height: '0.8125rem',
                        }}/>
                    </ArrowDiv>
                </Button>
            </Link>
        </Main>
    )
}

export {ParticipateButton};