import styled from 'styled-components';
import { theme } from '@theme';

const { border, color, typography, breakpoints } = theme;

export const Main = styled.div`
  border: 1px solid ${color.grey[200]};
  border-radius: ${border.default};
  margin-bottom: 15px;
  
  &:last-child {
      margin-bottom: 0;
    }
    `;

export const Head = styled.div`
    padding: 22px 30px;
    cursor: pointer;

    ${breakpoints.down('md', `
        padding: 22px 15px;
    `)}
`;

export const Content = styled.div`
  padding: 0 30px;
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  
  ${breakpoints.down('md', `
      padding: 0 15px;
  `)}

  &.active {
    visibility: visible;
    max-height: 100vh;
  }

  &.inactive {
    transition: all 0s ease-in-out;
    visibility: hidden;
    max-height: 0;
  }
`;

export const Columns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Column = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.h1`
    font-size: 20px;
    font-weight: ${typography.fontWeightBold};
    color: ${color.grey[900]};
`;

export const ToggleIcon = styled.div`
`;
