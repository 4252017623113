import React, { HTMLProps } from "react";
import { Main } from './styles';

interface ButtonProps {
  className?: string;
  link?: string;
  text: string;
  withBorder: boolean;
  outPage?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: "button" | "submit";
  sx?: {
    buttonBorderColor?: string;
    buttonBgColor?: string;
    buttonColor?: string;
  }
}

const Button: React.FC<ButtonProps & HTMLProps<HTMLButtonElement>> =
  (props: ButtonProps & HTMLProps<HTMLButtonElement>) => {

    const { className, link, text, withBorder, onClick, children, outPage, disabled, type, style, sx } = props;

    return (
      <Main
        withBorder={withBorder}
        sx={sx}
        style={{ ...style }}
        className={`${className} ${outPage ? 'out-page' : ''} ${onClick ? "without-link" : ''}`}
        onClick={onClick}
        disabled={disabled}
        type={type ?? "button"}
      >

        {onClick
          ? outPage ? [children, text] : [text, children]
          : <a className="anchor" href={link} target={link !== '#!' ? "_blank" : "_self"} rel="noreferrer">{children ? [text, children] : text}</a>
        }

      </Main>
    )
  }

export { Button };