
import Skeleton from 'react-loading-skeleton'
import {
    ButtonWrapper,
    Content,
    HeaderContainer,
    HeaderNavColumn,
    HeaderWrapper,
    MobileMenuIcon,
    WrapperCommonSkeleton,
} from './styles'

const CommonPreloader: React.FC = () => {
    return (
        <WrapperCommonSkeleton>
            <HeaderWrapper>
                <HeaderContainer>
                    <div>
                        <Skeleton width={80} height={32} />
                    </div>
                    <HeaderNavColumn>
                        <Skeleton width={140} count={1} />
                        <Skeleton width={140} count={1} />
                        <Skeleton width={65} count={1} />
                        <Skeleton width={120} count={1} />
                        <Skeleton borderRadius={5} width={185} height={50}  />
                    </HeaderNavColumn>
                    <MobileMenuIcon>
                        <Skeleton width={30} height={30} />
                    </MobileMenuIcon>
                </HeaderContainer>
            </HeaderWrapper>
            <Content>
                <ButtonWrapper>
                    <Skeleton width={248} height={47} />
                </ButtonWrapper>
                <Skeleton style={{ marginBottom: '10px' }} count={20} />
            </Content>
        </WrapperCommonSkeleton>
    )
}

export { CommonPreloader }