import React from "react";

import {Main, HeaderBox} from './styles';

interface HowWorksBoxProps {
    step: number;
    children: React.ReactNode;
    style: {
        boxColor: string;
        labelColor: string;
    }
}

const HowWorksBox: React.FC<HowWorksBoxProps> =
  ({ step, children, style}: HowWorksBoxProps) => {
    return (
        <Main
            style={{
                borderColor: style.boxColor
            }}
            className="mainBox">
            <HeaderBox
                bgColor={style.boxColor}
                color={style.labelColor}
                className="headerBox">
                {step}
            </HeaderBox>
            {children}
        </Main>
    )
}

export {HowWorksBox};