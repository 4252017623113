import React from 'react';
import { ThemeContext } from './context';
import { themingProvider } from '../../hooks/theming';
import { ProviderProps } from '@interfaces/Contexts';


const ThemingProvider = ({ children }: ProviderProps) => {
    const value = themingProvider();

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
};

export {
    ThemingProvider,
};
