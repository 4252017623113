import styled from "styled-components";
import {theme} from '@theme';

export const Main = styled.div`
    height: 100%;
`;

export const Text = styled.div`
    display: flex;
    max-height: 210px;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
`;

interface ThemeProps {
    color: string;
}

export const Title = styled.h2<ThemeProps>`
    font-size: 20px;
    color: ${props => props.color};
`;

export const WrapperSubtitle = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 14px;
    justify-content: center;
`

export const Subtitle = styled.p`
    text-align: center;
    font-size: 14px;
    line-height: 1.8;
    color: ${theme.color.grey[700]}
`;