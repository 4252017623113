import styled from "styled-components";

import {theme} from '@theme';

interface MainProps {
    backgroundColor: string;
    textColor: string;
}

export const Main = styled.div<MainProps>`
    padding: 27px 20px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0 .375rem .375rem 0;
    flex-grow: 1;
`;

export const Title = styled.h1`
    font-size: 20px;
    padding-bottom: 14px;
`;

export const Subtitle = styled.p`
    font-size: 14px;
    line-height: 1.5;
    font-weight: ${theme.typography.fontWeightRegular};
`;