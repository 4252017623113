import { ParticipantAuthKeysProps, PreRegisteredParticipantProps, RegisterTypeInformationProps } from "@interfaces/Auth";

const RESPONSE = {
    isLoaded: false,
    status: 0,
    message: '',
};

export const DEFAULTS = {
    PARTICIPANT_AUTH_KEYS: {
        ...RESPONSE,
        data: [] as ParticipantAuthKeysProps[]
    },
    PRE_REGISTERED_PARTICIPANT_INFOS: {
        ...RESPONSE,
        data: {} as PreRegisteredParticipantProps
    },
    REGISTER_TYPE_INFOS: {
        ...RESPONSE,
        data: {} as RegisterTypeInformationProps
    },
}