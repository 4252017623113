
export const scrollToSection = () => {
    const urlSplit = window.location.href.split('#')
    const idSection = urlSplit[urlSplit.length - 1]

    if (idSection.includes(window.location.host)) {
        return window.scrollTo({
            top: 0
        })
    }

    const getSection = document.getElementById(idSection)
    !!getSection && getSection.scrollIntoView()
}
