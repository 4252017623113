import React from 'react';
import { AuthContext } from './context';
import { authProvider } from '../../hooks/auth';
import { ProviderProps } from '@interfaces/Contexts';


const AuthProvider = ({ children }: ProviderProps) => {
    const auth = authProvider();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
};

export {
    AuthProvider,
};
