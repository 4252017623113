import { HotsiteThemeStyles } from "@interfaces/theme/hotsite/StylesJSON";
import { HotsiteTextsJSONProps } from "@interfaces/theme/hotsite/TextsJSON";

export const hotsiteThemeTexts: HotsiteTextsJSONProps = {
    themeName: "hotsite-theme",
    header: {
        headerNav: {
            headerNavData: [
                {
                    id: "1",
                    title: "Como funciona",
                    link: "#como-funciona",
                    _id: "62e4377044a3d315054fb4d6"
                },
                {
                    id: "2",
                    title: "Campanhas",
                    link: "#campanhas",
                    _id: "62e4377044a3d315054fb4d5"
                },
                {
                    id: "3",
                    title: "FAQ",
                    link: "#duvidas-frequentes",
                    _id: "62e4377044a3d315054fb4d7"
                },
                {
                    id: "4",
                    title: "Fale Conosco",
                    link: "#!",
                    _id: "62e4377044a3d315054fb4d8"
                }
            ]
        },
        headerLogo: "/files/placeholder/logo.webp",
        headerButtonCreateAccount: {
            headerButtonCreateAccountText: "Criar conta",
            headerButtonCreateAccountLink: "#!"
        },
        headerButtonAccess: {
            headerButtonAccessText: "Acesse",
            headerButtonAccessLink: "#!"
        }
    },
    footer: {
        footerLogo: "/files/placeholder/logo.webp",
        footerNav: [
            {
                id: "1",
                title: "Campanhas",
                link: "#campanhas",
                _id: "62e4377044a3d315054fb4d9"
            },
            {
                id: "2",
                title: "Como funciona",
                link: "#como-funciona",
                _id: "62e4377044a3d315054fb4da"
            },
            {
                id: "3",
                title: "FAQ",
                link: "#duvidas-frequentes",
                _id: "62e4377044a3d315054fb4db"
            },
            {
                id: "4",
                title: "Fale Conosco",
                link: "#!",
                _id: "62e4377044a3d315054fb4dc"
            }
        ]
    },
    heroSlider: [
        {
            id: "86db3679-983f-4480-b89c-fddbf1f00875",
            order: 1,
            src: "/files/placeholder/bannerDesktop1.webp",
            srcMobile: "/files/placeholder/bannerMobile1.webp",
            link: "#!!",
            _id: "63da6646eabc6a0d5cc3c11e"
        },
        {
            id: "710371ce-0880-41f8-bb29-bac573cea221",
            order: 2,
            src: "/files/placeholder/bannerDesktop2.webp",
            srcMobile: "/files/placeholder/bannerMobile2.webp",
            link: "#!!",
            _id: "647a5f2158e2a734429aed9d"
        }
    ],
    section1: {
        section1Visibility: 'true',
        section1Headline: {
            section1HeadlineText: "Como funciona"
        },
        section1ComponentData: [
            {
                section1ComponentDataId: "4bb215f6",
                section1ComponentDataTitle: "Escolha a campanha",
                section1ComponentDataSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                _id: "62e4377044a3d315054fb4de"
            },
            {
                section1ComponentDataId: "4bb21c0e",
                section1ComponentDataTitle: "Se Inscreva",
                section1ComponentDataSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                _id: "62e4377044a3d315054fb4df"
            },
            {
                section1ComponentDataId: "4bb21d62",
                section1ComponentDataTitle: "Tudo Pronto!",
                section1ComponentDataSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                _id: "62e4377044a3d315054fb4e0"
            }
        ]
    },
    section2: {
        section2Headline: {
            section2HeadlineText: "Campanhas ativas"
        }
    },
    faq: {
        faqTitle: {
            faqTitleText: "FAQ"
        },
        faqContent: [
            {
                id: "9f15b938",
                title: "Sobre as premiações",
                questions: [
                    {
                        id: "4997eoe9",
                        title: "Quais são os prêmios?",
                        text: "Sorteios todos os dias",
                        _id: "62e4377044a3d315054fb4f0"
                    },
                    {
                        id: "8b945i30",
                        title: "Como saber quem são os ganhadores?",
                        text: "teste @1234$%##$",
                        _id: "62e4377044a3d315054fb4f1"
                    },
                    {
                        id: "0ac19r88",
                        title: "O que preciso fazer para receber a premiação?",
                        text: "Estar apto a promoção",
                        _id: "62e4377044a3d315054fb4f2"
                    }
                ],
                _id: "62e4377044a3d315054fb4ef"
            },
            {
                id: "63194253-cae4-4597-b94a-88b65fad978f",
                title: "Prêmios",
                questions: [
                    {
                        id: "1a9e7006-08fa-4ed8-b147-56bddd75320d",
                        title: "Quais prêmios eu posso ganhar?",
                        text: "Quais prêmios eu posso ganhar?",
                        _id: "6390e1cd76c01173ecaa5c1f"
                    },
                    {
                        id: "a81b3ee6-1f3d-4e1b-bbcd-9f43ef39034f",
                        title: "Brick by boring brick",
                        text: "Quais prêmios eu posso ganhar?",
                        _id: "6390e1cd76c01173ecaa5c20"
                    }
                ],
                _id: "6390e1cd76c01173ecaa5c1e"
            },
            {
                id: "a6a9ef22-1e1d-45ae-b2a0-2b193ca5dbf1",
                title: "Editar titulo",
                questions: [
                    {
                        id: "6fd0e21e-438f-43ee-b68b-5edf3959aa0c",
                        title: "Exemplo de pergunta",
                        text: "teste",
                        _id: "63dab44939fd2a76b12bf5d5"
                    }
                ],
                _id: "63dab44939fd2a76b12bf5d4"
            },
            {
                id: "d9d13822-77a5-4d41-b650-d4fc4bf2f7d2",
                title: "Novo FAQ",
                questions: [
                    {
                        id: "e40d3baa-2230-4c03-b064-ffa010416670",
                        title: "Exemplo de pergunta",
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        _id: "647a5f2158e2a734429aed9b"
                    }
                ],
                _id: "647a5f2158e2a734429aed9a"
            }
        ]
    }
};

export const hotsiteThemeStyles: HotsiteThemeStyles = {
    headerHotsite: {
        bgColor: "#ffffff",
        navLinkColor: "#000000",
        navLinkActiveColor: "#c0c0c0",
        buttonAccessBorderColor: "#000000",
        buttonAccessColor: "#000000",
        buttonAuthBorderColor: "#000000",
        buttonAuthColor: "#000000"
    },
    howItWorks: {
        titleColor: "#c0c0c0",
        iconColor: "#c0c0c0",
        boxColor: "#c0c0c0",
        boxTitleColor: "#c0c0c0",
        boxLabelColor: "#ffffff"
    },
    activeCampaigns: {
        titleColor: "#c0c0c0",
        participateButtonBgColor: "#c0c0c0",
        participateButtonColor: "#ffffff"
    },
    faq: {
        titleColor: "#c0c0c0",
        toggleIconColor: "#c0c0c0"
    },
    footer: {
        footerColor: "#000000",
        footerBackgroundColor: "#f1f1f1",
    }
}

