import styled from 'styled-components';

import { theme } from '@theme';

const { typography, breakpoints } = theme;

interface MainProps {
  color: string
}

export const Main = styled.h1<MainProps>`
  color: ${props => props.color};
  font-size: 2.25rem;
  font-weight: ${typography.fontWeightBold};
  text-align: center;
  margin: 0 1.875rem 1.5625rem 1.875rem;
  
  ${breakpoints.down('md', `
    font-size: 1.625rem;
  `)}
`;
