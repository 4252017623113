import React from "react";
import { Layout } from '@components';
import { Main } from './styles';


const TermsOfUse: React.FC = () => {
    
    return (
        <Layout.CommonLayout>
            <Main>
                <h1>
                    Termos e Condições
                </h1>

                <p>Estes termos e condições descrevem as regras e regulamentos de uso do Site das Campanhas, localizado em campanhasonline.com.br.</p>
                <p>Ao acessar este site, assumimos que você aceita estes termos e condições. Não continue a usar campanhasonline se não concordar com todos os termos e condições indicados nesta página.</p>

                <h2>
                    Cookies
                </h2>

                <p>O site usa cookies para ajudar a personalizar sua experiência online. Ao acessar campanhasonline, você concordou em usar os cookies necessários.</p>
                <p>Um cookie é um arquivo de texto que é colocado em seu disco rígido por um servidor de página da web. Os cookies não podem ser usados ​​para executar programas ou entregar vírus ao seu computador. Os cookies são atribuídos exclusivamente a você e só podem ser lidos por um servidor da web no domínio que emitiu o cookie para você.</p>
                <p>Podemos usar cookies para coletar, armazenar e rastrear informações para fins estatísticos ou de marketing para operar nosso site. Você tem a capacidade de aceitar ou recusar Cookies opcionais. Existem alguns Cookies obrigatórios que são necessários para o funcionamento do nosso site. Esses cookies não requerem seu consentimento, pois sempre funcionam. Lembre-se de que, ao aceitar os cookies necessários, você também aceita cookies de terceiros, que podem ser usados ​​por meio de serviços fornecidos por terceiros se você usar esses serviços em nosso site, por exemplo, uma janela de exibição de vídeo fornecida por terceiros e integrada em nosso site.</p>

                <h2>
                    Licença:
                </h2>

                <p>Salvo disposição em contrário, as Campanhas e/ou seus licenciantes detêm os direitos de propriedade intelectual de todos os materiais de campanhas online. Todos os direitos de propriedade intelectual são reservados. Você pode acessá-lo a partir de campanhasonline para seu uso pessoal sujeito às restrições estabelecidas nestes termos e condições.</p>

                <h2>
                    Você não deve:
                </h2>

                <ul>
                    <li>Copiar ou republicar material de campanhasonline</li>
                    <li>Vender, alugar ou sublicenciar material de campanhasonline</li>
                    <li>Reproduza, duplique ou copie material de campanhasonline</li>
                    <li>Redistribuir conteúdo de campanhasonline</li>
                    <li>Este Contrato terá início na presente data</li>
                </ul>

                <p>Partes deste site oferecem aos usuários a oportunidade de postar e trocar opiniões e informações em determinadas áreas do site. Campanhas não filtra, edita, publica ou revisa comentários antes de sua presença no site. Os comentários não refletem os pontos de vista e opiniões das Campanhas, seus agentes e/ou afiliados. Os comentários refletem os pontos de vista e opiniões da pessoa que publica seus pontos de vista e opiniões. Na medida do permitido pelas leis aplicáveis, as Campanhas não serão responsáveis ​​pelos Comentários ou qualquer responsabilidade, danos ou despesas causados ​​e/ou sofridos como resultado de qualquer uso e/ou publicação e/ou apresentação dos Comentários neste local na rede Internet.</p>

                <p>Campanhas se reserva o direito de monitorar todos os Comentários e remover quaisquer Comentários que possam ser considerados inadequados, ofensivos ou que causem violação destes Termos e Condições.</p>

                <h2>Você garante e declara que:</h2>

                <ul>
                    <li>Você tem o direito de postar os Comentários em nosso site e tem todas as licenças e consentimentos necessários para fazê-lo;</li>
                    <li>Os Comentários não invadem nenhum direito de propriedade intelectual, incluindo, sem limitação, direitos autorais, patentes ou marcas registradas de terceiros;</li>
                    <li>Os Comentários não contêm nenhum material difamatório, calunioso, ofensivo, indecente ou ilegal, o que é uma invasão de privacidade.</li>
                    <li>Os Comentários não serão usados ​​para solicitar ou promover negócios ou costumes ou apresentar atividades comerciais ou atividades ilegais.</li>
                    <li>Você concede às Campanhas uma licença não exclusiva para usar, reproduzir, editar e autorizar outros a usar, reproduzir e editar qualquer um de seus Comentários em toda e qualquer forma, formato ou mídia</li>
                </ul>

                <p>Você concede às Campanhas uma licença não exclusiva para usar, reproduzir, editar e autorizar outros a usar, reproduzir e editar qualquer um de seus Comentários em toda e qualquer forma, formato ou mídia.</p>

                <h2>Hiperlinks para o nosso conteúdo:</h2>

                <p>As seguintes organizações podem vincular-se ao nosso site sem aprovação prévia por escrito:</p>

                <ul>
                    <li>Agências governamentais;</li>
                    <li>Motores de busca;</li>
                    <li>Organizações de notícias;</li>
                    <li>Os distribuidores de diretórios on-line podem vincular-se ao nosso site da mesma maneira que vinculam aos sites de outras empresas listadas; </li>
                    <li>Empresas credenciadas em todo o sistema, exceto organizações sem fins lucrativos, shopping centers de caridade e grupos de angariação de fundos de caridade que não podem ter links para nosso site.</li>
                </ul>

                <p>Essas organizações podem criar links para nossa página inicial, publicações ou outras informações do site, desde que o link: (a) não seja de forma alguma enganoso; (b) não implique falsamente patrocínio, endosso ou aprovação da parte vinculada e seus produtos e/ou serviços; e (c) se enquadre no contexto do site da parte vinculada.</p>

                <p>Podemos considerar e aprovar outras solicitações de link dos seguintes tipos de organizações:</p>

                <ul>
                    <li>Fontes de informações de consumidores e/ou negócios comumente conhecidas;</li>
                    <li>Sites da comunidade ponto.com;</li>
                    <li>Associações ou outros grupos que representam instituições de caridade;</li>
                    <li>Distribuidores de diretórios on-line;</li>
                    <li>Portais de internet;</li>
                    <li>Empresas de contabilidade, advocacia e consultoria; e</li>
                    <li>Instituições de ensino e associações comerciais.</li>
                </ul>

                <p>Aprovaremos solicitações de links dessas organizações se decidirmos que: (a) o link não nos faria parecer desfavorável a nós mesmos ou aos nossos negócios credenciados; (b) a organização não possui registros negativos conosco; (c) o benefício para nós da visibilidade do hiperlink compensa a ausência de Campanhas; e (d) o link está no contexto de informações gerais sobre recursos.</p>

                <p>Essas organizações podem criar links para nossa página inicial, desde que o link: (a) não seja enganoso de forma alguma; (b) não implique falsamente patrocínio, endosso ou aprovação da parte vinculada e seus produtos ou serviços; e (c) se enquadre no contexto do site da parte vinculada.</p>

                <p>Se você é uma das organizações listadas no parágrafo 2 acima e está interessado em se conectar ao nosso site, você deve nos informar enviando um e-mail para Campanhas. Inclua seu nome, nome da sua organização, informações de contato, bem como o URL do seu site, uma lista de todos os URLs dos quais você pretende vincular ao nosso site e uma lista dos URLs do nosso site para os quais você gostaria de link. Aguarde 2-3 semanas por uma resposta.</p>

                <p>As organizações aprovadas podem criar links para o nosso site da seguinte forma:</p>

                <ul>
                    <li>Pelo uso de nosso nome corporativo; ou</li>
                    <li>Pelo uso do localizador uniforme de recursos que está sendo vinculado; ou</li>
                    <li>O uso de qualquer outra descrição do nosso site vinculado a isso faz sentido dentro do contexto e formato do conteúdo no site da parte vinculante.</li>
                </ul>

                <p>Não será permitido o uso do logotipo da Campanhas ou outra arte para vinculação sem um contrato de licença de marca.</p>

                <h2>Responsabilidade do conteúdo:</h2>

                <p>Não nos responsabilizamos por qualquer conteúdo que apareça em seu site. Você concorda em nos proteger e nos defender contra todas as reivindicações levantadas em seu site. Nenhum link(s) deve(m) aparecer em qualquer site que possa ser interpretado como difamatório, obsceno ou criminoso, ou que infrinja, viole de outra forma ou defenda a violação ou outra violação de quaisquer direitos de terceiros.</p>

                <h2>Reserva de Direitos:</h2>

                <p>Reservamo-nos o direito de solicitar que você remova todos os links ou qualquer link específico para o nosso site. Você aprova a remoção imediata de todos os links para nosso site mediante solicitação. Também nos reservamos o direito de alterar estes termos e condições e sua política de vinculação a qualquer momento. Ao vincular continuamente ao nosso site, você concorda em se comprometer e seguir estes termos e condições de vinculação.</p>

                <h2>Remoção de links do nosso site:</h2>

                <p>Se você encontrar algum link em nosso site que seja ofensivo por qualquer motivo, você pode entrar em contato e nos informar a qualquer momento. Consideraremos solicitações para remover links, mas não somos obrigados a responder a você diretamente.</p>

                <p>Não garantimos que as informações contidas neste site estejam corretas. Não garantimos sua integridade ou precisão, nem prometemos garantir que o site permaneça disponível ou que o material no site seja atualizado.</p>

                <h2>Isenção de responsabilidade:</h2>

                <p>Na extensão máxima permitida pela lei aplicável, excluímos todas as representações, garantias e condições relacionadas ao nosso site e ao uso deste site. Nada neste aviso legal irá:</p>

                <ul>
                    <li>Limitar ou excluir nossa ou sua responsabilidade por morte ou danos pessoais;</li>
                    <li>Limitar ou excluir nossa ou sua responsabilidade por fraude ou deturpação fraudulenta;</li>
                    <li>Limitar qualquer uma de nossas ou suas responsabilidades de qualquer forma que não seja permitida pela lei aplicável; ou</li>
                    <li>Excluir qualquer uma de nossas ou suas responsabilidades que não possam ser excluídas pela lei aplicável.</li>
                </ul>

                <p>As limitações e proibições de responsabilidade estabelecidas nesta Seção e em outras partes desta isenção de responsabilidade: (a) estão sujeitas ao parágrafo anterior; e (b) reger todas as responsabilidades decorrentes da isenção de responsabilidade, incluindo responsabilidades decorrentes de contrato, delito e violação de dever legal.</p>

                <p>Desde que o site e as informações e serviços no site sejam fornecidos gratuitamente, não seremos responsáveis ​​por quaisquer perdas ou danos de qualquer natureza.</p>
            </Main>
        </Layout.CommonLayout>
    )
}

export { TermsOfUse };