import { theme } from '@theme';
import styled from "styled-components";

const { shadow, color, border, zIndex, breakpoints } = theme;

const maxContent = (max: number) => `
    max-width: ${max}px;
    width: 100%;
    margin: 0 auto;
`;

export const Main = styled.div`
    border: .0625rem solid ${color.grey[50]};
    box-shadow: ${shadow.default};
    background-color: ${color.common.white};
    padding: 32px;
    border-radius: ${border.default};
    position: relative;
    z-index: ${zIndex.min};
    width: 100%;
    height: 100%;
    max-height: 515px;
    overflow: scroll;
    overflow-x: hidden;
    word-break: break-word;

    & > ol {
        margin-bottom: 1.5rem;
        & > li {
            margin-bottom: 0.75rem;
        }
    }

    & > h1 {
        font-size: 1.375rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }
    & > h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }
    & > h3 {
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
    }
    & > h4,h5,h6 {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
    }
    & > p {
        font-size: 0.875rem;
        margin-bottom: 1rem;
    }

    ${breakpoints.down('sm', `
        max-height: 100%;
    `)}
`;

export const MessageInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;

    & > svg {
        font-size: 26px;
        margin-bottom: 8px;
    }
`;

export const P = styled.p`
    margin-bottom: 15px;
    font-size: 14px;
    color: ${color.grey[900]};
`;