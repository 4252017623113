import { ContactFormIsEnabledProps } from "@interfaces";
import { client } from '../../services/client';
import React from "react";
import { DEFAULTS } from "./DEFAULTS";
export const useApp = () => {
    const { services } = client();

    const [
        contactForm,
        setContactForm,
    ] = React.useState(DEFAULTS.APP.CONTACT_FORM);

    const loadContactFormIsEnabled = async ({
        websiteAliasCompany,
        websiteAliasProgram,
        websiteAliasCampaign,
    }: ContactFormIsEnabledProps) => {
        const response = await services.app.contactFormIsEnabled({
            websiteAliasCompany,
            websiteAliasProgram,
            websiteAliasCampaign,
        });

        setContactForm((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                {},
        });
        return response;
    };
    return {
        contactForm, loadContactFormIsEnabled
    }
}