import styled from 'styled-components';
import { theme } from '@theme';

const { color, zIndex, breakpoints } = theme;

interface DialogProps {
    maxWidth: 'md' | 'sm';
}

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${zIndex.modal};
`;

export const DialogRoot = styled.div<DialogProps>`
    background-color: ${color.common.white};
    max-width: ${props => props.maxWidth === 'md' ? '826px' : '600px'};
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.4);
    border-radius: 8px;
    position: relative;
    z-index: ${zIndex.modal + 100};
    ${breakpoints.down('md', `
        margin: 32px;
    `)}

    ${breakpoints.down('sm', `
        border-radius: 0;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        height: 100%;
    `)}
`;

export const HeaderModal = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${theme.border.default};
    padding: 16px;
`;

export const Title = styled.h1`
    font-size: 20px;
    line-height: 1.6;
    color: ${theme.color.grey[900]};
    font-weight: ${theme.typography.fontWeightBold};

    ${breakpoints.down('sm', `
        font-size: 18px;
    `)}
`;

export const TitleDiv = styled.div`
`;

export const CloseButton = styled.div`
    position: absolute;
    right: 0;
    border: none;
    background: none;
    cursor: pointer;
    padding: 14px 19px;
    z-index: ${theme.zIndex.modal};
`;

export const Children = styled.div`
    height: 100%;
    ${breakpoints.down('sm', `
        height: calc(100% - 60px);
    `)}
`;
