import { theme } from "@theme";
import styled from "styled-components";

export const WrapperHotsiteSkeleton = styled.div`
    overflow-y: hidden;
`;

export const HeaderWrapper = styled.div`
    height: 98px;
    box-shadow: 0 4px 12px 4px rgba(0,0,0,0.1);
    position: relative;
    z-index: 999;
`;

export const HeaderContainer = styled.div`
    max-width: 1140px;
    padding: 0 15px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('lg', `
        max-width: 960px;
    `)}


    ${theme.breakpoints.down('md', `
        padding: 0 30px;
        max-width: 720px;
    `)}

    ${theme.breakpoints.down('sm', `
        max-width: 540px;
    `)}
`;

export const HeaderNavColumn = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    ${theme.breakpoints.down('md', `
        display: none;
    `)}
`;

export const MobileMenuIcon = styled.div`
    width: 54px;
    display: none;
    justify-content: center;

    ${theme.breakpoints.down('md', `
        display: flex;
    `)}
`;

export const BannerSlider = styled.div`
    width: 100%;
    height: 520px;

    ${theme.breakpoints.down('md', `
        height: 400px;
    `)}
`;

export const WrapperSection = styled.div`
    max-width: 1140px;
    margin: 100px auto 0 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const WrapperSectionItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;

    & > span {
        width: 100%;
    }

    ${theme.breakpoints.down('md', `
        flex-direction: column;

    `)}
`;

export const WrapperSectionTitle = styled.div`
    margin-bottom: 30px;
    max-width: 290px;
    width: 100%;

    ${theme.breakpoints.down('md', `
        padding: 0 30px;

    `)}
`;