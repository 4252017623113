import styled from 'styled-components';
import { theme } from '@theme';
export const Main = styled.div`
    border-bottom: 1px solid ${theme.color.grey[200]};
    background-color: ${theme.color.common.white};
    & > .bootstrap-wrapper  {
        padding: 30px 0;
        & > .container {
            position: relative;
        }
    }
`;

export const ButtonBackHistory = styled.button`
    /* width: 768px; */
    cursor: pointer;
    border: none;
    background-color: #FFFFFF;
    padding: 10px 15px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    
    & > span {
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        margin-left: 10px;
        color: #828182;
    }

    & > svg {
        position: relative;
        top: 1px;
    }
`;

export const ClientLogo = styled.div`

`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
