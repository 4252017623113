import React from "react";
import { LogoIcon, NavItem, Grid, Container, Dialog, ContactForm, Regulament } from '@design-system';
import LogoCommarket from '@img/logo-commarket.png';
import { Main,  Nav, Ul, Informative, CompanyInfo, ContainerStyled } from './styles';
import { ModalStaticProps } from '@interfaces/dialog';
import { theme as t } from "@theme";
import { location } from '@utils';
import { CONSTANTS } from "@constants/index";
import { useParams } from "react-router-dom";


const dataFooter = [
    {
        title: 'Termos de uso',
        link: 'terms-of-use'
    },
    {
        title: 'Políticas de privacidade',
        link: 'privacy-policies'
    },
]

interface NavItemProps {
    title: string;
    link: string;
}

interface FooterProps {
    theme: string;
    logo: string;
    backgroundColor: string;
    fontColor: string;
    navData: NavItemProps[];
    custom: {
        navLinkActiveColor: string;
    }
}

const externalPaths = ['terms-of-use', 'privacy-policies']

const Footer: React.FC<FooterProps> =
  ({theme, backgroundColor, fontColor, logo, navData, custom}: FooterProps) => {
    
    const { campaignPath } = useParams();
    const getSubdomain = location.getSubdomain();
    const contactRef = React.useRef<ModalStaticProps>(null);
    const termsRef = React.useRef<ModalStaticProps>(null);

    const websiteAliasCompany = location.getFirstPathname() as string;

    const { color } = t;

    const handleDialog = (theme: string, key: string) => {

        if (theme === "campaign-theme") {

            switch (key) {

                case "regulamento":
                    termsRef.current?.show()
                    break;

                default:
                    (() => null)();
                    break;
            }
        }
        if (theme === "hotsite-theme") {

            switch (key) {
                case "fale-conosco":
                    contactRef.current?.show()
                    break;

                default:
                    (() => null)();
                    break;
            }
        }
    }

    const loadThemeObjectProps = {
        websiteAliasCompany: location.getSubdomain() === CONSTANTS.CAMPAIGN.LOCALHOST_DOMAIN ?
          CONSTANTS.CAMPAIGN.SUBDOMAIN :
          location.getSubdomain() as string,
        websiteAliasProgram: location.getFirstPathname() as string,
        websiteAliasCampaign: location.getSecondPathname() as string,
      }

    const handleNavLink = (link: string) => {
        const list = window.location.pathname.split('/')
        const path = list[list.length - 1]

        if (externalPaths.includes(path)) {
            return (`/${loadThemeObjectProps.websiteAliasProgram}${campaignPath ? '/'+loadThemeObjectProps.websiteAliasCampaign : ''}/${link}`)
        }
        return (link)

    }
    
    return (
        <>
            <Main fontColor={fontColor} backgroundColor={backgroundColor}>
                <Container>
                    <Grid container>
                        <Grid item lg={3} md={12}>
                            <LogoIcon className="on-footer" src={logo} link={campaignPath ? `/${campaignPath}` : '/'} />
                        </Grid>
                        <Grid item lg={3} md={12}>
                            <Informative className="on-footer">
                                Consulte condições de participação, datas dos sorteios e regulamento completo aqui no site. Imagens ilustrativas.
                            </Informative>
                        </Grid>
                        <Grid item lg={3} md={12}>
                            <Nav>
                                <Ul>
                                    {navData.map(({ title, link }, i) => {
                                        const titleLower = title.replace(" ", "-").toLowerCase();
                                            return (
                                                <NavItem
                                                    customStyle={{
                                                        color: '#000',
                                                        activeColor: '#000',
                                                    }}
                                                    key={`${title}-${i}`}
                                                    title={title}
                                                    link={link !== '#!' ? handleNavLink(link) : ''}
                                                    onFooter
                                                    isActive={false}
                                                    actionLink={() => handleDialog(theme, titleLower)}
                                                />
                                            )
                                        }
                                    )}
                                </Ul>
                            </Nav>
                        </Grid>
                        <Grid item lg={3} md={12}>
                            <Nav>
                                <Ul>
                                    <NavItem
                                        customStyle={{
                                            color: '#000',
                                            activeColor: '#000',
                                        }}
                                        key={'termos-de-uso-0'}
                                        title={'Termos de uso'}
                                        link={campaignPath ? `/${websiteAliasCompany}/${campaignPath}/terms-of-use` : `/${websiteAliasCompany}/terms-of-use`}
                                        onFooter
                                        isActive={false}
                                    />
                                    <NavItem
                                        customStyle={{
                                            color: '#000',
                                            activeColor: '#000',
                                        }}
                                        key={'politica-de-privacidade-1'}
                                        title={'Políticas de privacidade'}
                                        link={campaignPath ? `/${websiteAliasCompany}/${campaignPath}/privacy-policies` : `/${websiteAliasCompany}/privacy-policies`}
                                        onFooter
                                        isActive={false}
                                    />
                                </Ul>
                            </Nav>
                        </Grid>
                    </Grid>
                </Container>
                <CompanyInfo>
                    <ContainerStyled>
                        <Informative>
                            <span>© 2024 Campanhas online</span>
                        </Informative>
                        <Informative>
                            <span>Desenvolvido por </span>
                            <img src={LogoCommarket} alt="commarket" />
                        </Informative>
                    </ContainerStyled>
                </CompanyInfo>

                <>
                    <Dialog maxWidth="sm" ref={contactRef} desactivateLink={() => null}>
                        <ContactForm
                            onPage={false}
                            backgroundColorButton={custom.navLinkActiveColor} fontColorButton={color.common.white}
                        />
                    </Dialog>

                    <Dialog maxWidth="md" ref={termsRef} title="Regulamento" desactivateLink={() => null}>
                        <Regulament />
                    </Dialog>
                </>
            </Main>
        </>
    )
}

export { Footer };
