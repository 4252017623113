import React from "react";
import {Button} from '@design-system';

interface ButtonIconProps {
    text: string;
    iconSrc: React.ReactNode;
    link?: string;
    outPage?: boolean;
    onClick?: () => void;
    sx?: {
        buttonBgColor: string;
        buttonColor: string;
    }
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
    iconSrc, link, text, onClick, outPage, sx}: ButtonIconProps) => {
    return (
        <Button
            className="button-icon"
            link={link}
            sx={{
                buttonBgColor: sx?.buttonBgColor,
                buttonColor: sx?.buttonColor
            }}
            text={text}
            onClick={onClick}
            outPage={outPage}
            withBorder={false}>
            {iconSrc}
        </Button>
    )
}

export {ButtonIcon};