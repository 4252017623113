/* eslint-disable max-len */
import { ContactFormIsEnabledProps } from '@interfaces/index';
import { client } from '../../client';

export const contactFormIsEnabled = async ({
    websiteAliasCompany,
    websiteAliasProgram,
    websiteAliasCampaign,
}: ContactFormIsEnabledProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('CAMPAIGN');
    const aliasCampaign = websiteAliasCampaign ? `?websiteAliasCampaign=${websiteAliasCampaign}` : '';
    try {
        const response = await instance.get(`/checkTalkToUs/company/${websiteAliasCompany}/program/${websiteAliasProgram}${aliasCampaign}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};
