import styled from 'styled-components';
import { theme } from '@theme';

const { breakpoints } = theme;

interface SectionProps {
  color: string;
  visibility?: 'true' | 'false'
}


export const Main = styled.section<SectionProps>`
  padding-top: 120px;
  display: ${props => props?.visibility === 'false' ? 'none' : 'block'};
  background-color: ${props => props.color};

  &.last {
    padding-bottom: 3.75rem;
  }
  
  ${breakpoints.down('md', `
    padding-top: 6.875rem;

    &.last {
      padding-bottom: 2.5rem;
    }
  `)}
`;
