import React from "react";
import { client } from "../../services/client";
import { AccessTypesProps, CreateParticipantFormDataProps, PreRegisterListKeysProps, PreRegisterValidateKeysProps, PreRegisteredParticipantInfoProps, SendParticipantFormDataProps } from "@interfaces/AuthService";
import { DEFAULTS } from "./DEFAULTS";


export const usePreRegister = () => {
    const [
        participantAuthKeys,
        setParticipantAuthKeys
    ] = React.useState(DEFAULTS.PARTICIPANT_AUTH_KEYS);
    const [
        preRegisteredParticipantInfo,
        setPreRegisteredParticipantInfo
    ] = React.useState(DEFAULTS.PRE_REGISTERED_PARTICIPANT_INFOS);
    const [registrationTypeInformation,
        setRegistrationTypeInformation] = React.useState(DEFAULTS.REGISTER_TYPE_INFOS);
    const [registrationToken, setRegistrationToken] = React.useState('');
    const { services } = client();

    const loadRegisterTypeInformation = async ({ websiteAliasCompany, websiteAliasProgram }: AccessTypesProps) => {
        const response = await services.auth.registerTypeInformation({ websiteAliasCompany, websiteAliasProgram });
        setRegistrationTypeInformation((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                {},
        });
        return response;
    }

    const loadParticipantAuthKeys = async ({ subdomain }: PreRegisterListKeysProps) => {
        const response = await services.auth.loadKeys({ subdomain });
        setParticipantAuthKeys((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                [],
        });
        return response;
    }

    const validateKeys = async ({ subdomain, data }: PreRegisterValidateKeysProps) => {
        const response = await services.auth.validateKeys({ subdomain, data });
        if (!response?.error) {
            const registrationToken = response?.response?.payload.registrationToken;
            if (registrationToken) {
                setRegistrationToken(registrationToken)
            }
        }
        return response;
    }

    const loadPreRegisteredParticipantInfo = async ({ token }: PreRegisteredParticipantInfoProps) => {
        const response = await services.auth.loadPreRegisteredParticipantInfo({ token });
        setPreRegisteredParticipantInfo((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                {},
        });
        return response;
    }

    const sendParticipantFormData = async ({ token, data }: SendParticipantFormDataProps) => {
        const response = await services.auth.sendParticipantFormData({ token, data });
        return response;
    }

    const createCampaignParticipant = async ({ subdomain, data }: CreateParticipantFormDataProps) => {
        const response = await services.auth.createCampaignParticipant({ subdomain, data });
        return response;
    }

    const requestNewEmail = async () => {
        const response = await services.auth.requestNewEmail();
        return response;
    }

    return {
        registerTypeInformations: {
            load: loadRegisterTypeInformation,
            list: registrationTypeInformation,
        },
        participantKeys: {
            list: participantAuthKeys,
            load: loadParticipantAuthKeys,
            validateKeys: validateKeys,
        },
        participant: {
            registrationToken,
            preRegisteredInfos: {
                load: loadPreRegisteredParticipantInfo,
                list: preRegisteredParticipantInfo,
                register: sendParticipantFormData,
                requestNewEmail
            },
            campaign: {
                create: createCampaignParticipant
            }
        }
    }
};
