export const settings = {
  className: 'slick-carousel-center',
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: 30,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
      }
    },
    {
      breakpoint: 770,
      settings: {
        centerPadding: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      }
    },
    {
      breakpoint: 460,
      settings: {
        centerPadding: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      }
    },
  ]
};

export const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};