import React, { HTMLProps } from "react";
import { Main, NavLink } from './styles';

interface NavItemProps {
    title: string;
    link: string;
    isActive: boolean;
    onFooter?: boolean;
    onClick?: () => void;
    actionLink?: () => void;
    customStyle: {
        color: string;
        activeColor: string
    }
}

const NavItem: React.FC<NavItemProps> = (
    { title, link, isActive, onClick, actionLink, onFooter, customStyle }: NavItemProps
) => {
    return (
        <Main onClick={onClick} className={`navItem ${onFooter ? 'footer' : ''}`}>
            {link !== '' ?
                <a href={link} style={{ textDecoration: 'none' }} onClick={actionLink} className='anchor'>
                    <NavLink
                        color={customStyle.color}
                        activeColor={customStyle.activeColor}
                        className={`navLink ${isActive ? onFooter ? 'active footer' : 'active' : onFooter ? 'footer' : ''}`}
                    >
                        {title}
                    </NavLink>
                </a>
            
            :
            <NavLink
                onClick={actionLink}
                color={customStyle.color}
                activeColor={customStyle.activeColor}
                className={`navLink ${isActive ? onFooter ? 'active footer' : 'active' : onFooter ? 'footer' : ''}`}
            >
                {title}
            </NavLink>
            }
        </Main>
    )
}

export { NavItem };