// ActiveCampaignsProps
import React from "react";
import { DEFAULTS } from "./DEFAULTS";
import { client } from '../../services/client';
import { CampaignThemeStyles } from "@interfaces/theme/campaign/StylesJSON";
import { campaignThemeStyles, campaignThemeTexts } from "@constants/theme/campaign";
import { LoadThemeFilesProps } from "@interfaces/CampaignServicesNOVO";
import { CampaignTextsJSONProps } from "@interfaces/theme/campaign/TextsJSON";

export const useCampaign = () => {
    const [theme, setTheme] = React.useState(DEFAULTS.THEME.CAMPAIGN);
    const { services } = client();

    const getNestedValue = <T, U>(obj: T, path: string, defaultValue: U): U => {
        const keys = path.split('.');
        const result = keys.reduce((acc: any, key: string) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
        return (result !== undefined ? result : defaultValue) as U;
    };

    const loadTheme = async ({ websiteAliasCompany, websiteAliasProgram, websiteAliasCampaign, fileType }: LoadThemeFilesProps) => {
        setTheme(DEFAULTS.THEME.CAMPAIGN)
        const response = await services.campaign.loadThemeFiles({
            websiteAliasCompany,
            websiteAliasProgram,
            websiteAliasCampaign,
            fileType,
        });
        
        if (fileType === "styles") {
            // const response = await loadThemeFile({ websiteAliasCompany, websiteAliasProgram, websiteAliasCampaign, fileType });
            const styles = await response?.response as CampaignThemeStyles;

            const stylesTheme: CampaignThemeStyles = {
                headerCampaign: {
                    bgColor: getNestedValue(styles, "headerCampaign.bgColor", campaignThemeStyles.headerCampaign.bgColor),
                    navLinkColor: getNestedValue(styles, "headerCampaign.navLinkColor", campaignThemeStyles.headerCampaign.navLinkColor),
                    navLinkActiveColor: getNestedValue(styles, "headerCampaign.navLinkActiveColor", campaignThemeStyles.headerCampaign.navLinkActiveColor),
                    buttonAccessBorderColor: getNestedValue(styles, "headerCampaign.buttonAccessBorderColor", campaignThemeStyles.headerCampaign.buttonAccessBorderColor),
                    buttonAccessColor: getNestedValue(styles, "headerCampaign.buttonAccessColor", campaignThemeStyles.headerCampaign.buttonAccessColor),
                    buttonAuthBorderColor: getNestedValue(styles, "headerCampaign.buttonAuthBorderColor", campaignThemeStyles.headerCampaign.buttonAuthBorderColor),
                    buttonAuthColor: getNestedValue(styles, "headerCampaign.buttonAuthColor", campaignThemeStyles.headerCampaign.buttonAuthColor),
                },
                howToParticipate: {
                    titleColor: getNestedValue(styles, "howToParticipate.titleColor", campaignThemeStyles.howToParticipate.titleColor),
                    campos: {
                        backgroundColor: getNestedValue(styles, "howToParticipate.campos.backgroundColor", campaignThemeStyles.howToParticipate.campos.backgroundColor),
                        textColor: getNestedValue(styles, "howToParticipate.campos.textColor", campaignThemeStyles.howToParticipate.campos.textColor),
                        iconBackgroundColor: getNestedValue(styles, "howToParticipate.campos.iconBackgroundColor", campaignThemeStyles.howToParticipate.campos.iconBackgroundColor),
                        iconColor: getNestedValue(styles, "howToParticipate.campos.iconColor", campaignThemeStyles.howToParticipate.campos.iconColor),
                    },
                },
                notRegisteredYet: {
                    titleColor: getNestedValue(styles, "notRegisteredYet.titleColor", campaignThemeStyles.notRegisteredYet.titleColor),
                    textColor: getNestedValue(styles, "notRegisteredYet.textColor", campaignThemeStyles.notRegisteredYet.textColor),
                    buttonBgColor: getNestedValue(styles, "notRegisteredYet.buttonBgColor", campaignThemeStyles.notRegisteredYet.buttonBgColor),
                    buttonColor: getNestedValue(styles, "notRegisteredYet.buttonColor", campaignThemeStyles.notRegisteredYet.buttonColor),
                },
                alreadyRegistered: {
                    titleColor: getNestedValue(styles, "alreadyRegistered.titleColor", campaignThemeStyles.alreadyRegistered.titleColor),
                    textColor: getNestedValue(styles, "alreadyRegistered.textColor", campaignThemeStyles.alreadyRegistered.textColor),
                    buttonBgColor: getNestedValue(styles, "alreadyRegistered.buttonBgColor", campaignThemeStyles.alreadyRegistered.buttonBgColor),
                    buttonColor: getNestedValue(styles, "alreadyRegistered.buttonColor", campaignThemeStyles.alreadyRegistered.buttonColor),
                },
                meetPartners: {
                    titleColor: getNestedValue(styles, "meetPartners.titleColor", campaignThemeStyles.meetPartners.titleColor),
                    sliderArrowsColor: getNestedValue(styles, "meetPartners.sliderArrowsColor", campaignThemeStyles.meetPartners.sliderArrowsColor),
                },
                faq: {
                    titleColor: getNestedValue(styles, "faq.titleColor", campaignThemeStyles.faq.titleColor),
                    toggleIconColor: getNestedValue(styles, "faq.toggleIconColor", campaignThemeStyles.faq.toggleIconColor),
                },
                contact: {
                    bgColor: getNestedValue(styles, "contact.bgColor", campaignThemeStyles.contact.bgColor),
                    buttonBgColor: getNestedValue(styles, "contact.buttonBgColor", campaignThemeStyles.contact.buttonBgColor),
                    buttonColor: getNestedValue(styles, "contact.buttonColor", campaignThemeStyles.contact.buttonColor),
                },
                footer: {
                    footerLogo: getNestedValue(styles, "footer.footerLogo", campaignThemeStyles.footer.footerLogo),
                    footerBackgroundColor: getNestedValue(styles, "footer.footerBackgroundColor", campaignThemeStyles.footer.footerBackgroundColor),
                    footerColor: getNestedValue(styles, "footer.footerColor", campaignThemeStyles.footer.footerColor),
                },
            }

            setTheme((prev) => prev = {
                texts: { ...prev.texts },
                styles: {
                    message: response?.response.message,
                    isLoaded: !!(!response?.error),
                    status: response?.response.status,
                    data: response?.response ?
                    stylesTheme :
                        campaignThemeStyles,
                }
            });
            return;
        }

        if (fileType === "texts") {
            const texts = response?.response as CampaignTextsJSONProps;
            
            // campaignThemeTexts
            setTheme((prev) => prev = {
                styles: { ...prev.styles },
                texts: {
                    message: response?.response.message,
                    isLoaded: !!(!response?.error),
                    status: response?.response.status,
                    data: response?.response ?
                        texts :
                        campaignThemeTexts,
                }
            });
        }
    };
    return { theme, loadTheme };
};
