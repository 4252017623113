import React from 'react';
import { Routes as AppRoutes, Route, useLocation } from 'react-router-dom';
import {
    Hotsite,
    Campaign,
    TermsOfUse,
    PrivacyPolicies,
    NotFound
} from '@pages';
import { Layout } from '@components';

const Routes = () => {

    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <React.Fragment>
            <AppRoutes>
                <Route element={<Layout.DefaultLayout />}>
                    <Route index={true} element={<Hotsite />} />
                    <Route path={`/:campaignPath`} element={<Campaign />} />
                    <Route path={`/terms-of-use`} element={<TermsOfUse />} />
                    <Route path={`/:campaignPath/terms-of-use`} element={<TermsOfUse />} />
                    <Route path={`/privacy-policies`} element={<PrivacyPolicies />} />
                    <Route path={`/:campaignPath/privacy-policies`} element={<PrivacyPolicies />} />
                    {/* <Route path={`/:clean`} element={<CleanPage />} /> */}
                    <Route path='*' element={<NotFound />} />
                </Route>
            </AppRoutes>
        </React.Fragment>
    )
}

export default Routes;