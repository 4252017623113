import { ObjectFitType } from 'src/types';
import styled from 'styled-components';

interface WapperProps {
    maxHeight?: number
    objectFit?: ObjectFitType
    children: React.ReactNode
    fixedWidth: boolean
    className?: string
  }
  
  export const Main: React.FC<WapperProps> = styled.div<WapperProps>`
      display: inline-flex;
      & > .WrapAnchorPicture {
          height: 100vh;
          max-height: ${(props) => props.maxHeight ?? 120}px;
          width: ${(props) => props.fixedWidth ? props.maxHeight + 'px' : 'auto'};
          cursor: pointer;
  
          & > .AnchorPicture {
              display: block;
              height: 100%;
              width: 100%;
              overflow: hidden;
  
              & > .WrapPicture {
                  height: 100%;
                  width: 100%;
                  picture {
                      source,
                      img {
                          width: 100%;
                          height: 100%;
                          object-fit: ${(props) => props.objectFit ?? 'cover'};
                          border: 0;
                          max-width: 100%;
                          display: inline-block;
                          vertical-align: middle;
                      }
                  }
                  video {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border: 0;
                      max-width: 100%;
                      display: inline-block;
                      vertical-align: middle;
                  }
              }
          }
      }
  `