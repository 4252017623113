/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { settingsSlider } from './configs';

import {
  Section,
  Container,
  Accordion,
  AccordionSummary,
  Headline,
  Slider,
  ContactForm,
  ButtonIcon,
  Banner,
  Grid,
  HowWorksCampaign,
  CampaignPreloader,
} from '@design-system';

import {
  Main,
  MainSlider,
  SectionContact,
  FeaturedBox,
  ColumnBox,
  BoxTitle,
  BoxText,
  PartnerWarning,
  FaqSpaceBottom,
  ContactFormWrapper,
  FaqWrapper,
  FaqContainer,
} from './styles';

import { Patterns } from '@components';

import { location, scrollToSection, menu } from '@utils';

import { useTheme } from '../../hooks/theming';
import { Unpublished } from '../common/unpublished';
import { CONSTANTS } from '@constants/index';
import { useParams } from 'react-router-dom';

const Campaign: React.FC = () => {
  const params = useParams();

  const aliasCompany = CONSTANTS.ALIAS.COMPANY(location.getSubdomain() as string);
  const aliasProgramCode = CONSTANTS.ALIAS.PROGRAM_CODE(location.getFirstPathname() as string);
  const aliasCampaign = CONSTANTS.ALIAS.CAMPAIGN(params.campaignPath as string);

  const { campaign, app } = useTheme();

  const [questionId, setQuestionId] = React.useState('')

  const settingsBannersSlider = {
    ...settingsSlider,
    nextArrow: <button><I icon={['far', 'chevron-right']} size="3x" /></button>,
    prevArrow: <button><I icon={['far', 'chevron-left']} size="3x" /></button>,
  };

  React.useEffect(() => {
    if (campaign.theme.styles.isLoaded &&
      campaign.theme.texts.isLoaded) {
      scrollToSection()
    }
  }, [
    campaign.theme.styles.isLoaded,
    campaign.theme.texts.isLoaded,
  ]);

  React.useEffect(() => {
    app.loadContactFormIsEnabled({
      websiteAliasCompany: aliasCompany,
      websiteAliasProgram: aliasProgramCode,
      websiteAliasCampaign: aliasCampaign,
    })
  }, []);

  const loading = (
    !campaign.theme.texts.isLoaded &&
    campaign.theme.texts.status <= 200 &&
    !campaign.theme.styles.isLoaded &&
    campaign.theme.styles.status <= 200);

  const notPublished = (
    !campaign.theme.texts.isLoaded &&
    campaign.theme.texts.status >= 400 &&
    !campaign.theme.styles.isLoaded &&
    campaign.theme.styles.status >= 400);

  return (
    <React.Fragment>
      <Main>
        {loading && <CampaignPreloader />}
        {campaign.theme.texts.isLoaded && campaign.theme.styles.isLoaded && (
          <>
            <Patterns.Header
              custom={campaign.theme.styles.data.headerCampaign}
              theme={campaign.theme.texts.data.themeName}
              className='headerCampaign'
              logo={`${campaign.theme.texts.data.header.headerLogo}`}
              navData={menu(
                campaign.theme.texts.data.header.headerNav.headerNavData,
                '#como-participar',
                app.contactForm.data.existsTalkToUs,
                campaign.theme.texts.data.section1.section1Visibility,
                campaign.theme.texts.data.section1.section1Headline.section1HeadlineText,
              )}
              // navData={campaign.theme.texts.data.section1.section1Visibility !== 'false' ? [
              //   {
              //     id: '0',
              //     link: '#1',
              //     title: campaign.theme.texts.data.section1.section1Headline.section1HeadlineText
              //   },
              //   ...campaign.theme.texts.data.header.headerNav.headerNavData.slice(1)
              // ] : campaign.theme.texts.data.header.headerNav.headerNavData.slice(1)}
              buttonCreateAccount={campaign.theme.texts.data.header.headerButtonCreateAccount}
              buttonAccess={campaign.theme.texts.data.header.headerButtonAccess}
            />

            <Slider className='main-slider' {...settingsBannersSlider}>
              {campaign.theme.texts.data.heroSlider.map(({ id, src, srcMobile, link }) => (
                <Banner key={id} src={`${location.filesPath(src)}`} srcMobile={`${location.filesPath(srcMobile)}`} link={link} />
              ))}
            </Slider>

            {campaign.theme.texts.data.section1.section1Visibility !== 'false' &&
              <Section visibility={campaign.theme.texts.data?.section1?.section1Visibility} id='1'>
                <Container>
                  <Headline
                    color={campaign.theme.styles.data.howToParticipate.titleColor}
                    className='headlineColor'>{campaign.theme.texts.data.section1.section1Headline.section1HeadlineText}</Headline>
                  <Grid container>
                    {campaign.theme.texts.data.section1.section1ComponentData.map(
                      ({ section1ComponentDataId, section1ComponentDataIconData, section1ComponentDataTitle, section1ComponentDataSubtitle, section1ComponentDataImage }) => {
                        return (
                          <Grid key={section1ComponentDataId} item lg={4} md={12}>
                            <HowWorksCampaign
                              srcImage={section1ComponentDataImage}
                              sx={campaign.theme.styles.data.howToParticipate.campos}
                              iconData={section1ComponentDataIconData as IconProp}
                              title={section1ComponentDataTitle}
                              subtitle={section1ComponentDataSubtitle} />
                          </Grid>
                        )
                      })}
                  </Grid>
                </Container>
              </Section>
            }

            {(((campaign.theme.texts.data.section2?.section2_box1?.section2Box1Visibility !== 'false')) || (campaign.theme.texts.data.section2?.section2_box2?.section2_box2_visibility !== 'false')) &&
              <Container>
                {(campaign.theme.texts.data?.section2?.section2_box1?.section2Box1Visibility !== 'false') &&
                  <Section id='auth-section1'>
                    <FeaturedBox className='left mr-b box1'>
                      <ColumnBox className='column info'>
                        <BoxTitle
                          color={campaign.theme.styles.data.notRegisteredYet.titleColor}>
                          {campaign.theme.texts.data.section2.section2_box1?.section2Box1Title}</BoxTitle>
                        <BoxText color={campaign.theme.styles.data.notRegisteredYet.textColor}>
                          {campaign.theme.texts.data.section2.section2_box1?.section2Box1Text}</BoxText>
                        <ButtonIcon
                          sx={{
                            buttonBgColor: campaign.theme.styles.data.notRegisteredYet.buttonBgColor,
                            buttonColor: campaign.theme.styles.data.notRegisteredYet.buttonColor
                          }}
                          // link={`${location.getCampaignDomain()}/portal/auth/pre${campaignPath ? '?campaign=' + campaignPath : ''}`}
                          link={campaign.theme.texts.data.section2.section2_box1.section2Box1Button.section2Box1ButtonLink}
                          text={campaign.theme.texts.data.section2.section2_box1.section2Box1Button.section2Box1ButtonText}
                          iconSrc={<I icon={['far', 'long-arrow-right']}
                            size="lg"
                            className='icon' />} />
                      </ColumnBox>
                      <ColumnBox className='column image'>
                        <div className="WrapAnchorPicture">
                          <div className="AnchorPicture">
                            <div className="WrapPicture">
                              <picture>
                                <img src={`${location.filesPath(campaign.theme.texts.data.section2.section2_box1.section2Box1Image)}`} alt="Campanhas" />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </ColumnBox>
                    </FeaturedBox>
                  </Section>
                }

                {(campaign.theme.texts.data.section2.section2_box2.section2_box2_visibility !== 'false') &&
                  <Section id='auth-section2'>
                    <FeaturedBox className='right box2'>
                      <ColumnBox className='column info right'>
                        <BoxTitle color={campaign.theme.styles.data.alreadyRegistered.titleColor}>
                          {campaign.theme.texts.data.section2.section2_box2.section2_box2_title}</BoxTitle>
                        <BoxText color={campaign.theme.styles.data.alreadyRegistered.textColor}>
                          {campaign.theme.texts.data.section2.section2_box2.section2_box2_text}</BoxText>
                        <ButtonIcon
                          sx={{
                            buttonBgColor: campaign.theme.styles.data.alreadyRegistered.buttonBgColor,
                            buttonColor: campaign.theme.styles.data.alreadyRegistered.buttonColor
                          }}
                          // link={`${location.getFirstPathname()}/portal/login${campaignPath ? '?campaign=' + campaignPath : ''}`}
                          link={campaign.theme.texts.data.section2.section2_box2.section2_box2_button.section2_box2_button_link}
                          text={campaign.theme.texts.data.section2.section2_box2.section2_box2_button.section2_box2_button_text}
                          iconSrc={<I icon={['far', 'long-arrow-right']}
                            size="lg"
                            className='icon' />} />
                      </ColumnBox>
                      <ColumnBox className='column image'>
                        <div className="WrapAnchorPicture">
                          <div className="AnchorPicture">
                            <div className="WrapPicture">
                              <picture>
                                <img src={`${location.filesPath(campaign.theme.texts.data.section2.section2_box2.section2_box2_image)}`} alt="Campanhas" />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </ColumnBox>
                    </FeaturedBox>
                  </Section>
                }
              </Container>
            }

            <Section id='conheca-parceiros' sectionColor={'#fff'}>
              <Container>
                <Headline
                  color={campaign.theme.styles.data.meetPartners.titleColor}
                  className='headlineColor'>
                  {campaign.theme.texts.data.section3.section3_headline.section3HeadlineText}</Headline>
                <MainSlider>
                  {/* <Slider {...sliderSettings} className='campaign-slider'>
                    {campaign.theme.texts.data.section3.section3_sectionSlider.map(
                      ({ section3SectionSliderId, section3SectionSliderImage, section3SectionSliderTitle, section3SectionSliderDescription }) => (
                        <Card
                          key={section3SectionSliderId}
                          banner={section3SectionSliderImage}
                          title={section3SectionSliderTitle}
                          description={section3SectionSliderDescription}
                        />
                      ))}
                  </Slider> */}
                  <PartnerWarning>
                    <span>No momento não há catálogo disponível</span>
                  </PartnerWarning>
                </MainSlider>
              </Container>
              <div id='duvidas-frequentes' />
            </Section>

            <Section id='duvidas-frequentes-campaign'>
              <Container>
                <FaqContainer>
                  <FaqWrapper>
                    <Headline
                      color={campaign.theme.styles.data.faq.titleColor}
                      className='headlineColor'>
                      {campaign.theme.texts.data.faq.faqTitle.faqTitleText}
                    </Headline>
                      {campaign.theme.texts.data.faq?.faqContent?.map(({ id, title, questions }) => {
                        return (
                          <Accordion
                            key={`${id}`}
                            title={title}
                            className="accordionCampaign"
                            color={campaign.theme.styles.data.faq.toggleIconColor}
                          >
                            {questions.map(({ id, title, text }, i) => {
                              const index = i + 1;
                              return (
                                <AccordionSummary
                                  onClick={() => setQuestionId(id !== questionId ? id : '')}
                                  isActive={questionId === id}
                                  key={`${id}`}
                                  count={index}
                                  title={title}
                                  color={campaign.theme.styles.data.faq.toggleIconColor}>
                                  {text}
                                </AccordionSummary>
                              )
                            })}
                          </Accordion>
                        )
                      })}
                  </FaqWrapper>
                </FaqContainer>
                {app.contactForm.isLoaded &&
                  !app.contactForm.data.existsTalkToUs && (
                    <FaqSpaceBottom />
                  )}
              </Container>

            </Section>
            {app.contactForm.isLoaded &&
              app.contactForm.data.existsTalkToUs && (
                <SectionContact
                  className='last'
                  sectionColor={campaign.theme.styles.data.contact.bgColor}
                  id='fale-conosco'>
                  <Section className='last' id='section-form'>
                    <Container>
                      <ContactFormWrapper>
                        <ContactForm
                          onPage
                          backgroundColorButton={campaign.theme.styles.data.contact.buttonBgColor}
                          fontColorButton={campaign.theme.styles.data.contact.buttonColor}
                        />
                      </ContactFormWrapper>
                    </Container>
                  </Section>
                </SectionContact>
              )}

            <Patterns.Footer
              custom={campaign.theme.styles.data.headerCampaign}
              logo={`${location.filesPath(campaign.theme.texts.data.footer.footerLogo)}`}
              backgroundColor={campaign.theme.styles.data.footer.footerBackgroundColor}
              fontColor={campaign.theme.styles.data.footer.footerColor}
              navData={menu(
                campaign.theme.texts.data.footer.footerNav,
                '#como-participar',
                app.contactForm.data.existsTalkToUs,
                campaign.theme.texts.data.section1.section1Visibility,
                campaign.theme.texts.data.section1.section1Headline.section1HeadlineText
              )}
              // navData={campaign.theme.texts.data.section1.section1Visibility !== 'false' ? [
              //   {
              //     link: '#1',
              //     title: campaign.theme.texts.data.section1.section1Headline.section1HeadlineText
              //   },
              //   ...campaign.theme.texts.data.footer.footerNav.slice(1)
              // ] : campaign.theme.texts.data.footer.footerNav.slice(1)}
              theme={campaign.theme.texts.data.themeName} />
          </>
        )}
        {notPublished && <Unpublished />}
      </Main>
    </React.Fragment>
  );
}

export { Campaign };