import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 0 8px;
  
  &:hover {
    & .dropdown {
      display: block;
    }
  }
`;


export const AvatarWrapper = styled.div`
  width: 38px;
  min-width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarPlaceHolderMain = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: Poppins;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    color: rgb(255, 255, 255);
    background-color: rgb(189, 189, 189);
    border: 0.0625rem solid rgb(0, 0, 0);
    width: 38px;
    min-width: 38px;
    height: 38px;
    cursor: default;
    & > svg {
        width: 28px;
        height: 28px;
    }
    & > svg > path {
        fill: rgb(255, 255, 255);
    }
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UserContent = styled.div`
  margin-left: 0.5rem;
`;

export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
`;

export const UserName = styled.p`
    margin: 0;
    font-family: Poppins;
    font-weight: 300;
    font-size: 3.75rem;
    line-height: 1.2;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.125rem;
    white-space: nowrap;
`

export const UserNameSub = styled.p`
    margin: 0;
    font-family: Poppins;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    font-size: 0.6875rem;
    font-weight: 400;
    white-space: nowrap;
`
export const ButtonCaret = styled.button`
  padding: 0.625rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const Dropdown = styled.ul`
  position: absolute;
  z-index: 999;
  border-radius: 0.25rem;
  left: 0;
  top: 40px;
  list-style: none;
  background: #fff;
  width: 178px;
  max-height: 300px;
  overflow-y: hidden;
  transition: height 0.3s ease, opacity 0.4s ease;
  display: none;
  box-shadow: 0 0.25rem 0.75rem 0.25rem rgba(0,0,0,0.1);
  `;
  
  export const DropdownItem = styled.li`
  cursor: pointer;
  display: flex;
  padding: 0.4rem 0.4rem;
  align-items: center;
  font-size: 14px;
  color: #212121;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:last-child {
      border-bottom: 0;
  }
`;

export const DropdownItemText = styled.p`
    margin: 0;
    font-family: Poppins;
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.167;
    color: #010101;
    text-transform: none;
    font-size: 0.875rem;
    white-space: nowrap;
    font-weight: 400;
`;
    
  export const DropdownItemIcon = styled.div`
    background-color: #ECF0F1;
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.625rem;
    border-radius: 3px;
   
`