import styled from 'styled-components';
import { theme } from '@theme';
import { svg } from '@utils';

interface PropsMain {
    backgroundColor: string;
    fontColor: string;
}

const { breakpoints, zIndex } = theme;

export const Main = styled('header')`
    padding: 23px 0;
    /* z-index: ${zIndex.max} !important; */

    &.sticker-active {
        -webkit-box-shadow: 0 4px 12px 4px rgba(0,0,0,0.1);
        box-shadow: 0 4px 12px 4px rgba(0,0,0,0.1);
    }

    ${breakpoints.down('md', `
        padding: 0;
    `)};

    & > .wrapper {
        display: flex;
        align-items: stretch;
        
        .container {
            display: flex;
            align-items: stretch;
        }
    }
`;

export const Columns = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${breakpoints.down('md', `
        padding: 0;
        flex-direction: column;
    `)};
`;

export const Column = styled.div<Partial<PropsMain>>`

    &.header-control {
        display: flex;
        justify-content: space-between;
        & > .svg {
            padding-right: 15px;
            display: none;
        }
    
        ${svg({ fill: '#51187E', height: 20 })};

        ${theme.breakpoints.down('md', `
            width: 100%;
            height: 100px;
            align-items: center;
            & > .svg {
                display: block;
            }
            :first-child {
                padding-left: 15px;
            }
        `)};
    }

    &.second-column {
        
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        background-color: ${({ backgroundColor }) => backgroundColor};
        ${theme.breakpoints.down('md', `
            position: fixed;
            top: 100px;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: ${zIndex.min};
            padding-left: 15px;
            padding-right: 15px;
            justify-content: flex-start;
            flex-direction: column;
            display: none;
        `)};

        & > button {
            ${theme.breakpoints.down('md', `
                max-width: 252px;
                width: 100%;
            `)};
            
            &:last-child {
                margin-left: 1.25rem;
                
                ${theme.breakpoints.down('md', `
                    margin-top: .625rem;
                    margin-left: 0;
                `)};
            }
        }
    }

    &.second-column.menu-mobile {
        ${theme.breakpoints.down('md', `
            display: flex;
                
        `)};
    }
`;

export const Nav = styled.nav`
    display: flex;
    align-items: center;

    ${breakpoints.down('md', `
        margin-bottom: 1.875rem;
    `)}

    ${theme.breakpoints.down('md', `
        align-items: center;
        flex-direction: column;
    `)};

`;

export const Ul = styled('ul')`
    display: flex;
    
    ${breakpoints.down('md', `
        align-items: center;
        flex-direction: column;
    `)}

    & > li {    
        ${breakpoints.down('md', `
            margin-bottom: .3125rem;
        `)}

        & > a {

            & > span {
                ${breakpoints.down('lg', `
                    font-size: .875rem;
                `)}

                ${breakpoints.down('md', `
                    font-size: 1rem;
                `)}
            }
        }
    }

`;

interface DropdownProps {
    height: string;
    opacity: number;
    display: string;
}

export const Dropdown = styled.ul<DropdownProps>`
  position: absolute;
  z-index: 999;
  border-radius: 0.25rem;
  right: 0;
  top: calc(100% + 2.75rem);
  list-style: none;
  background: #fff;
  width: 178px;
  max-height: ${props => props.height};
  overflow-y: hidden;
  padding: 0.4rem 0;
  transition: height 0.3s ease, opacity 0.4s ease;
  opacity: ${props => props.opacity};
  display: ${props => props.display};
  box-shadow: 0 0.25rem 0.75rem 0.25rem rgba(0,0,0,0.1);
`;

export const DropdownButton = styled.button`
    display: flex;
    justify-content: center;
    gap: 0.1875rem;
    padding: 0.25rem;
    border-radius: 3.125rem;
    background-color: transparent;
    border: none;
    cursor: pointer;

    & > span {
        background-color: #212121;
        height: 0.25rem;
        width: 0.25rem;
        border-radius: 3.125rem;
    }
`;

export const HeaderSvg = styled.div`
    margin-right: 15px;
    width: 54px;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    ${svg({ fill: '#51187E', height: 23 })};

    ${theme.breakpoints.down('md', `
        display: flex;
    `)};
`;
