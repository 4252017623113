import { AccessTokenProps } from "@interfaces/ClientServicesApi";
import { client } from '../../client';

export const loadParticipantInfo = async ({ token }: AccessTokenProps) => {
    const { handleInstance, handleErrors, handleResponse } = client();
    const instance = handleInstance('USER');
    try {
        const response = await instance.get('/participant/info', {
            headers: {
                'Authorization': `${token}`,
            },
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};