import { ParticipantDataProps } from "@interfaces/Participant";
import React from "react";
import { client } from '../../services/client';
import { AccessTokenProps } from "@interfaces/ClientServicesApi";

const RESPONSE = {
    isLoaded: false,
    status: 0,
    message: '',
};


const DEFAULTS = {
    PARTICIPANT_DATA: {
        ...RESPONSE,
        data: {} as ParticipantDataProps,
    },
};

export const useParticipant = () => {
    const { services } = client();

    const [
        participantInfo,
        setPartcipantInfo,
    ] = React.useState(DEFAULTS.PARTICIPANT_DATA);

    const loadParticipantInfos = async ({ token }: AccessTokenProps) => {
        const response = await services
            .participant
            .loadParticipantInfo({ token });

        setPartcipantInfo((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                {},
        });
        return response;
    };

    return {
        list: participantInfo,
        load: loadParticipantInfos,
    }
}