import React from "react";
import { AvatarGroup } from "@design-system";
import { ParticipantDataProps } from "@interfaces/Participant";
import { location } from '@utils';
import avatarPlaceholder from '@img/avatar-placeholder.png';

interface UserAvatarProps {
    participant: ParticipantDataProps;
    openAvatarMenu: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = (
    { participant, openAvatarMenu }: UserAvatarProps,
) => {
    return (
        <div
            style={{
                display: 'flex',
                cursor: 'default',
            }}>
            <AvatarGroup
                src={participant.photo ?
                    location.filesPath(`/files/${participant.photo}`) :
                    avatarPlaceholder}
                alt={participant.name}
                username={participant.name}
                sub={participant.position}
                openAvatarMenu={openAvatarMenu}
            />
        </div>
    )
}; 