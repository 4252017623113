/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from "react-router-dom"
import { ButtonContainer, Englob } from "./styles"
import { Patterns } from "@components"
import { ButtonIcon, CommonPreloader, Container } from "@design-system"
import { useTheme } from "../../../hooks/theming"
import { location } from "@utils"
import { Unpublished } from "../../../pages/common/unpublished";
import { loadGlobalServices } from "@load/global-services";

const CommonLayout: React.FC<{children: React.ReactNode}> = ({ children }: {
    children: React.ReactNode
}) => {
    const navigate = useNavigate()
    const { campaignPath } = useParams();
    const { hotsite, campaign } = useTheme();

    const globalServices = loadGlobalServices();

    React.useEffect(() => {
        globalServices.theme.loadHotsiteTheme(['texts', 'styles']);
        globalServices.theme.loadCampaignTheme(['texts', 'styles']);
    }, []);

    const loading = (
        !campaignPath && (!hotsite.theme.texts.isLoaded &&
        hotsite.theme.texts.status <= 200 &&
        !hotsite.theme.styles.isLoaded &&
        hotsite.theme.styles.status <= 200)) ||
        (campaignPath && (!campaign.theme.texts.isLoaded &&
            campaign.theme.texts.status <= 200 &&
            !campaign.theme.styles.isLoaded &&
            campaign.theme.styles.status <= 200));

    const notPublished = (
        !campaignPath && (!hotsite.theme.texts.isLoaded &&
        hotsite.theme.texts.status >= 400 &&
        !hotsite.theme.styles.isLoaded &&
        hotsite.theme.styles.status >= 400)) ||
        (campaignPath && (!campaign.theme.texts.isLoaded &&
            campaign.theme.texts.status >= 400 &&
            !campaign.theme.styles.isLoaded &&
            campaign.theme.styles.status >= 400));

    return (
        <Englob>
            {loading ? (<CommonPreloader />) :
                <>
                    {campaignPath ? (
                        <Patterns.Header
                            custom={campaign.theme.styles.data.headerCampaign}
                            theme={campaign.theme.texts.data.themeName}
                            className='headerCampaign'
                            logo={`${campaign.theme.texts.data.header.headerLogo}`}
                            navData={campaign.theme.texts.data.section1.section1Visibility !== 'false' ? [
                                {
                                  id: '0',
                                  link: '#1',
                                  title: campaign.theme.texts.data.section1.section1Headline.section1HeadlineText
                                },
                                ...campaign.theme.texts.data.header.headerNav.headerNavData.slice(1)
                              ] : campaign.theme.texts.data.header.headerNav.headerNavData.slice(1)}
                            buttonCreateAccount={campaign.theme.texts.data.header.headerButtonCreateAccount}
                            buttonAccess={campaign.theme.texts.data.header.headerButtonAccess}
                        />
                    )
                    
                    : (
                        <Patterns.Header
                                custom={hotsite.theme.styles.data.headerHotsite}
                                theme={hotsite.theme.texts.data.themeName}
                                className='headerHotsite'
                                logo={`${hotsite.theme.texts.data.header.headerLogo}`}
                                navData={hotsite.theme.texts.data.section1.section1Visibility !== 'false' ? [
                                    {
                                      id: '0',
                                      link: '#1',
                                      title: hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText
                                    },
                                    ...hotsite.theme.texts.data.header.headerNav.headerNavData.slice(1)
                                  ] : hotsite.theme.texts.data.header.headerNav.headerNavData.slice(1)}
                                buttonCreateAccount={hotsite.theme.texts.data.header.headerButtonCreateAccount}
                                buttonAccess={hotsite.theme.texts.data.header.headerButtonAccess}
                        />
                    )}
                    <Container>
                        <ButtonContainer>
                            <ButtonIcon 
                                outPage={true}
                                text={"Voltar para a home"}
                                onClick={() => navigate(campaignPath ? `/${campaignPath}` : '/')}
                                iconSrc={<I icon={['fal', 'arrow-circle-left']}
                                    size="lg"
                                    className='icon'/>} />
                        </ButtonContainer>
                        {children}
                    </Container>
                    {campaignPath ? (
                        <Patterns.Footer
                            custom={campaign.theme.styles.data.headerCampaign}
                            logo={`${location.filesPath(campaign.theme.texts.data.footer.footerLogo)}`}
                            backgroundColor={campaign.theme.styles.data.footer.footerBackgroundColor}
                            fontColor={campaign.theme.styles.data.footer.footerColor}
                            navData={campaign.theme.texts.data.section1.section1Visibility !== 'false' ? [
                                {
                                  link: '#1',
                                  title: campaign.theme.texts.data.section1.section1Headline.section1HeadlineText
                                },
                                ...campaign.theme.texts.data.footer.footerNav.slice(1)
                              ] : campaign.theme.texts.data.footer.footerNav.slice(1)}
                            theme={campaign.theme.texts.data.themeName} />
                    )
                    
                    : (
                        <Patterns.Footer
                            custom={hotsite.theme.styles.data.headerHotsite}
                            theme={hotsite.theme.texts.data.themeName}
                            logo={`${location.filesPath(hotsite.theme.texts.data.header.headerLogo)}`}
                            backgroundColor={hotsite.theme.styles.data.footer?.footerBackgroundColor}
                            fontColor={hotsite.theme.styles.data.footer?.footerColor}
                            navData={hotsite.theme.texts.data.section1.section1Visibility !== 'false' ? [
                                {
                                  link: '#1',
                                  title: hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText
                                },
                                ...hotsite.theme.texts.data.footer.footerNav.slice(1)
                              ] : hotsite.theme.texts.data.footer.footerNav.slice(1)}
                        />
                    )}
                </>
            }
            <>
                {notPublished && (<Unpublished />)}
            </>
        </Englob>
    )
}

export  { CommonLayout }
