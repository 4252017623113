/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";

import { useHotsite } from './hotsite';
import { ThemeContext } from "../../contexts/theme/context";
import { useCampaign } from "./campaign";
import { useApp } from "./app";

export const themingProvider = () => {
    const hotsite = useHotsite();
    const campaign = useCampaign();
    const app = useApp();

    return {
        hotsite,
        campaign,
        app,
    }
};

export const useTheme = () => {
    const context = React.useContext(ThemeContext);
    return context;
};
