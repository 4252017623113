import React from "react";
import { DEFAULTS } from "./DEFAULTS";
import { client } from '../../services/client';
import { LoadThemeFilesProps } from "@interfaces/HotsiteServicesNOVO";
import { HotsiteThemeStyles } from "@interfaces/theme/hotsite/StylesJSON";
import { hotsiteThemeStyles, hotsiteThemeTexts } from "@constants/theme/hotsite";
import { HotsiteTextsJSONProps } from "@interfaces/theme/hotsite/TextsJSON";

export const useHotsite = () => {
    const [theme, setTheme] = React.useState(DEFAULTS.THEME.HOTSITE);
    const { services } = client();

    const getNestedValue = <T, U>(obj: T, path: string, defaultValue: U): U => {
        const keys = path.split('.');
        const result = keys.reduce((acc: any, key: string) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
        return (result !== undefined ? result : defaultValue) as U;
    };

    const loadTheme = async ({ websiteAliasCompany, websiteAliasProgram, fileType }: LoadThemeFilesProps) => {
        const response = await services.hotsite.loadThemeFiles({
            websiteAliasCompany,
            websiteAliasProgram,
            fileType,
        });
        
        if (fileType === "styles") {
            const styles = await response?.response as HotsiteThemeStyles;

            const stylesTheme: HotsiteThemeStyles = {
                headerHotsite: {
                    bgColor: getNestedValue(styles, "headerHotsite.bgColor", hotsiteThemeStyles.headerHotsite.bgColor),
                    buttonAuthBorderColor: getNestedValue(styles, "headerHotsite.buttonAuthBorderColor", hotsiteThemeStyles.headerHotsite.buttonAuthBorderColor),
                    buttonAuthColor: getNestedValue(styles, "headerHotsite.buttonAuthColor", hotsiteThemeStyles.headerHotsite.buttonAuthColor),
                    buttonAccessBorderColor: getNestedValue(styles, "headerHotsite.buttonAccessBorderColor", hotsiteThemeStyles.headerHotsite.buttonAccessBorderColor),
                    buttonAccessColor: getNestedValue(styles, "headerHotsite.buttonAccessColor", hotsiteThemeStyles.headerHotsite.buttonAccessColor),
                    navLinkColor: getNestedValue(styles, "headerHotsite.navLinkColor", hotsiteThemeStyles.headerHotsite.navLinkColor),
                    navLinkActiveColor: getNestedValue(styles, "headerHotsite.navLinkActiveColor", hotsiteThemeStyles.headerHotsite.navLinkActiveColor),
                },
                howItWorks: {
                    titleColor: getNestedValue(styles, "howItWorks.titleColor", hotsiteThemeStyles.howItWorks.titleColor),
                    iconColor: getNestedValue(styles, "howItWorks.iconColor", hotsiteThemeStyles.howItWorks.iconColor),
                    boxColor: getNestedValue(styles, "howItWorks.boxColor", hotsiteThemeStyles.howItWorks.boxColor),
                    boxTitleColor: getNestedValue(styles, "howItWorks.boxTitleColor", hotsiteThemeStyles.howItWorks.boxTitleColor),
                    boxLabelColor: getNestedValue(styles, "howItWorks.boxLabelColor", hotsiteThemeStyles.howItWorks.boxLabelColor),
                },
                activeCampaigns: {
                    titleColor: getNestedValue(styles, "activeCampaigns.titleColor", hotsiteThemeStyles.activeCampaigns.titleColor),
                    participateButtonBgColor: getNestedValue(styles, "activeCampaigns.participateButtonBgColor", hotsiteThemeStyles.activeCampaigns.participateButtonBgColor),
                    participateButtonColor: getNestedValue(styles, "activeCampaigns.participateButtonColor", hotsiteThemeStyles.activeCampaigns.participateButtonColor),
                },
                faq: {
                    titleColor: getNestedValue(styles, "faq.titleColor", hotsiteThemeStyles.faq.titleColor),
                    toggleIconColor: getNestedValue(styles, "faq.toggleIconColor", hotsiteThemeStyles.faq.toggleIconColor),
                },
                footer: {
                    footerColor: getNestedValue(styles, "footer.footerColor", hotsiteThemeStyles.footer.footerColor),
                    footerBackgroundColor: getNestedValue(styles, "footer.footerBackgroundColor", hotsiteThemeStyles.footer.footerBackgroundColor),
                },
            }

            setTheme((prev) => prev = {
                texts: { ...prev.texts },
                styles: {
                    message: response?.response.message,
                    isLoaded: !!(!response?.error),
                    status: response?.response.status,
                    data: response?.response ?
                    stylesTheme :
                        hotsiteThemeStyles,
                }
            });
            return;
        }

        if (fileType === "texts") {
            const texts = response?.response as HotsiteTextsJSONProps;
            
            setTheme((prev) => prev = {
                styles: { ...prev.styles },
                texts: {
                    message: response?.response.message,
                    isLoaded: !!(!response?.error),
                    status: response?.response.status,
                    data: response?.response ?
                        texts :
                        hotsiteThemeTexts,
                }
            });
        }
    };

    return { theme, loadTheme };
};
