import React from "react";
import {HowWorksBoxCampaign} from '@design-system';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {Main, Title, Subtitle} from './styles';

interface HowWorksCampaignProps {
    iconData: IconProp;
    title: string;
    subtitle: string;
    srcImage: string
    sx?: {
        backgroundColor: string;
        textColor: string;
        iconBackgroundColor: string;
        iconColor: string;
    };
}

const HowWorksCampaign =
  ({iconData, title, subtitle, srcImage, sx}: HowWorksCampaignProps) => {
    return (
        <HowWorksBoxCampaign
          sx={{
            iconBackgroundColor: sx?.iconBackgroundColor ?? '',
            iconColor: sx?.iconColor ?? ''
          }}
          srcImage={srcImage}
          iconData={iconData}>
            <Main
                backgroundColor={sx?.backgroundColor ?? ''}
                textColor={sx?.textColor ?? ''}>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
            </Main>
        </HowWorksBoxCampaign>
    )
}

export {HowWorksCampaign};
