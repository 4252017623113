import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Main, Head, Title, ToggleIcon, Columns, Column, Content } from './styles';


interface AccordionProps {
    title: string;
    className: string;
    color?: string;
    children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, className, color, children }: AccordionProps) => {

    const [isActive, setIsActive] = React.useState(false)

    return (
        <Main className={className}>
            <Head onClick={() => setIsActive(!isActive)} className="headAccordion">
                <Columns className="columns">
                    <Column>
                        <Title>{title}</Title>
                    </Column>
                    <Column className="columnIcon">
                        <ToggleIcon className="toggleDiv">
                            <I icon={
                                !isActive
                                    ? ['fas', 'plus-circle']
                                    : ['fas', 'minus-circle']
                            } style={{ height: '1.625rem', color: color }}
                            className="toggleIcon"/>
                        </ToggleIcon>
                    </Column>
                </Columns>
            </Head>
            <Content className={isActive ? 'active' : 'inactive'}>
                {children}
            </Content>
        </Main>
    )
}

export { Accordion };