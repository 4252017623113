import { ListCampaignProps, LoadCampaignRegulationProps, TalkToUsCampaignProps } from "@interfaces/CampaignServicesNOVO";
import { client } from "../../services/client";
import React from "react";
import { DEFAULTS } from "./DEFAULTS";
import { CampaignContext } from "../../contexts/campaign/context";
import { useParticipant } from './participant';

export const useCampaignProvider = () => {
    const { services } = client();
    const participant = useParticipant();
    const [campaigns, setCampaigns] = React.useState(DEFAULTS.CAMPAIGN.LIST_CAMPAIGNS);

    const loadCampaigns = async ({ websiteAliasCompany, websiteAliasProgram }: ListCampaignProps) => {
        const response = await services.campaign.loadCampaigns({
            websiteAliasCompany,
            websiteAliasProgram,
        });
        setCampaigns((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                [],
        });
        return response;
    }

    const [
        campaignRegulation,
        setCampaignRegulation,
    ] = React.useState(DEFAULTS.CAMPAIGN.CAMPAIGNS_REGULANTION);

    const loadCampaignRegulation = async ({
        websiteAliasCompany, websiteAliasCampaign, websiteAliasProgram
    }: LoadCampaignRegulationProps) => {
        const response = await services.campaign.loadCampaignRegulation({
            websiteAliasCompany,
            websiteAliasCampaign,
            websiteAliasProgram
        });

        setCampaignRegulation((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response?.payload?.regulation ?
                response?.response?.payload?.regulation :
                '',
        });
        return response;
    };

    const contactForm = async ({
        websiteAliasCompany,
        websiteAliasCampaign,
        websiteAliasProgram,
        data,
    }: TalkToUsCampaignProps) => {
        const response = await services.campaign.talkToUs({
            websiteAliasCompany,
            websiteAliasCampaign,
            websiteAliasProgram,
            data,
        });
        return response;
    }
    return {
        participant,
        contactForm,
        campaignRegulation,
        loadCampaignRegulation,
        campaigns: {
            list: campaigns,
            load: loadCampaigns,
        }
    };
}

export const useCampaign = () => {
    const context = React.useContext(CampaignContext);
    return context;
};
