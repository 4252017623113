
import Skeleton from 'react-loading-skeleton'
import {
    BannerSlider,
    HeaderContainer,
    HeaderNavColumn,
    HeaderWrapper,
    MobileMenuIcon,
    WrapperHotsiteSkeleton,
    WrapperSection,
    WrapperSectionItems,
    WrapperSectionTitle,
} from './styles'

const CampaignPreloader: React.FC = () => {
    return (
        <WrapperHotsiteSkeleton>
            <HeaderWrapper>
                <HeaderContainer>
                    <div>
                        <Skeleton width={80} height={32} />
                    </div>
                    <HeaderNavColumn>
                        <Skeleton width={140} count={1} />
                        <Skeleton width={140} count={1} />
                        <Skeleton width={65} count={1} />
                        <Skeleton width={120} count={1} />
                        <Skeleton borderRadius={5} width={185} height={50}  />
                    </HeaderNavColumn>
                    <MobileMenuIcon>
                        <Skeleton width={30} height={30} />
                    </MobileMenuIcon>
                </HeaderContainer>
            </HeaderWrapper>
            <BannerSlider>
                <Skeleton height={'100%'} />
            </BannerSlider>
            <WrapperSection>
                <WrapperSectionTitle>
                    <Skeleton height={36} />
                </WrapperSectionTitle>
                <WrapperSectionItems>
                    <Skeleton borderRadius={6} height={130} />
                    <Skeleton borderRadius={6} height={130} />
                    <Skeleton borderRadius={6} height={130} />
                </WrapperSectionItems>
            </WrapperSection>
            <WrapperSection>

            </WrapperSection>
        </WrapperHotsiteSkeleton>
    )
}

export { CampaignPreloader }