import styled from 'styled-components';
import {theme} from '@theme';

export const Main = styled.div`
    border-radius: ${theme.border.default};
    border: 1px solid;
    width: 273px;
    height: 100%;
`;

interface HeaderBoxProps {
    bgColor: string;
    color: string;
}

export const HeaderBox = styled.label<HeaderBoxProps>`
    padding: 13px 0;
    display: flex;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 78px;
    font-weight: ${theme.typography.fontWeightBold};
    font-size: 52px;
`;