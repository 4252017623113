import styled from 'styled-components';

import { theme } from '@theme';

interface MainProps {
    withBorder: boolean;
    sx?: {
        buttonBorderColor?: string;
        buttonBgColor?: string;
        buttonColor?: string;
    }
}

const { border } = theme;

export const Main = styled('button')<MainProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${border.default};
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border: ${(props: MainProps) => props.withBorder === true ? 'solid 2px' : 'none'};
    white-space: nowrap;
    background-color: ${props => props.sx?.buttonBgColor};
    border-color: ${props => props.sx?.buttonBorderColor};
    color: ${props => props.sx?.buttonColor};

    & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11.5px 30px;
        text-decoration: none;
        white-space: nowrap; 
        color: ${props => props.sx?.buttonColor};
        
        svg {
            margin-left: .625rem;
        }
    }

    &.out-page {
        
            svg {
                margin-left: 0;
                padding-right: 10px;
            }
        
    }
    
    &.without-link {
        padding: 11.5px 30px;
    }

    &::disabled {
        opacity: 0.5;
    }
`;
