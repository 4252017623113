import { LoadThemeFilesProps } from '@interfaces/HotsiteServicesNOVO';
import { client } from '../../client';

export const loadThemeFiles = async ({ websiteAliasCompany, websiteAliasProgram, fileType }: LoadThemeFilesProps) => {
    const { handleErrors, handleResponse, handleInstance } = client();
    const instance = handleInstance('FILES');
    const file = `${fileType}.json`;
    console.log('SERVICE', websiteAliasCompany, websiteAliasProgram, file);
    
    
    try {
        const response = await instance.get(`${websiteAliasCompany}/${websiteAliasProgram}/${file}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};