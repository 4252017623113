import { hotsiteThemeTexts, hotsiteThemeStyles } from "@constants/theme/hotsite";
import { campaignThemeTexts, campaignThemeStyles } from "@constants/theme/campaign";
import { HotsiteThemeStyles } from "@interfaces/theme/hotsite/StylesJSON";
import { HotsiteTextsJSONProps } from "@interfaces/theme/hotsite/TextsJSON";
import { CampaignThemeStyles } from "@interfaces/theme/campaign/StylesJSON";
import { CampaignTextsJSONProps } from "@interfaces/theme/campaign/TextsJSON";

const RESPONSE = {
    isLoaded: false,
    status: 0,
    message: '',
};

export const DEFAULTS = {
    THEME: {
        HOTSITE: {
            texts: {
                ...RESPONSE,
                data: hotsiteThemeTexts as HotsiteTextsJSONProps,
            },
            styles: {
                ...RESPONSE,
                data: hotsiteThemeStyles as HotsiteThemeStyles,
            },
        },
        CAMPAIGN: {
            texts: {
                ...RESPONSE,
                data: campaignThemeTexts as CampaignTextsJSONProps,
            },
            styles: {
                ...RESPONSE,
                data: campaignThemeStyles as CampaignThemeStyles,
            },
        },
    },
    APP: {
        CONTACT_FORM: {
            ...RESPONSE,
            data: { existsTalkToUs: false },
        },
    }
};