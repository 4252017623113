import React from 'react';
import { maxText, location } from '@utils';
import { Main, CardHead, CardMedia, CardContent, Title, Description, LogoCampaign } from './styles';

import Placeholder640x410 from '@img/placeholder-640x410.png';

interface CardProps {
    logo?: string;
    banner: string;
    title: string;
    description: string;
    children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ logo, banner, title, description, children }: CardProps) => {
    
    return (
        <Main className='cardSlider'>
            <CardHead>
                <CardMedia>
                    {logo && (
                        <LogoCampaign src={`${location.filesPath(logo)}`} alt={title} />
                    )}
                    <div className="WrapAnchorPicture">
                        <div className="AnchorPicture">
                            <div className="WrapPicture">
                                <picture>
                                    <img
                                        src={`${location.filesPath(banner)}`}
                                        alt={title}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = Placeholder640x410;
                                        }}
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </CardMedia>
            </CardHead>
            <CardContent className='cardContent'>
                <div>
                    <Title>
                        {maxText({
                            value: title,
                            maxChars: 30
                        })}
                    </Title>
                    <Description>
                        {maxText({
                            value: description,
                            maxChars: 60
                        })}
                    </Description>
                </div>
                {children}
            </CardContent>
        </Main>
    )
}

export { Card };