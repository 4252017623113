import React from 'react';
import { AvatarImage, AvatarWrapper, Box, ButtonCaret, Dropdown, DropdownItem, DropdownItemIcon, DropdownItemText, Main, UserContent, UserName, UserNameSub } from './styles';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import avatarPlaceholder from '@img/avatar-placeholder.png';
import { location } from '@utils'
import { Link } from 'react-router-dom';

interface AvatarGroupProps {
    src: string;
    alt: string;
    openAvatarMenu: boolean,
    username: string;
    sub?: string;
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({ src, alt, username, openAvatarMenu, sub }: AvatarGroupProps) => {

    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const handleError = (e: any) => {
        e.target.src = avatarPlaceholder;
    };

    console.log('dropdown', dropdownOpen)

    return (
        <Main
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}>
            
            <Box>
                <AvatarWrapper>
                    <AvatarImage src={src} alt={alt} onError={handleError} />
                </AvatarWrapper>
                <UserContent>
                    <UserName>{username}</UserName>
                    {sub && (
                        <UserNameSub>{sub}</UserNameSub>
                    )}
                </UserContent>
            </Box>
            <Box>
                <ButtonCaret>
                    <I icon={['fas',
                        (openAvatarMenu || dropdownOpen) ?
                            'caret-up' :
                            'caret-down']}
                        style={{
                            fontSize: '0.875rem',
                            color: 'black',
                        }} />
                </ButtonCaret>
            </Box>
            <Dropdown className='dropdown'>
                <a style={{ textDecoration: 'none'}} href={location.redirectPortal({ pathTo: '/portal' })} rel='noreferrer'>
                    <DropdownItem>
                        <DropdownItemIcon>
                            <I icon={['far', 'share']} />
                        </DropdownItemIcon>
                        Ir pro portal
                    </DropdownItem>
                </a>
                {/* <DropdownItem>
                    <DropdownItemIcon>
                        <I icon={['fas', 'caret-down']} />
                    </DropdownItemIcon>
                    Profile
                </DropdownItem>
                <DropdownItem>
                    <DropdownItemIcon>
                        <I icon={['fas', 'caret-down']} />
                    </DropdownItemIcon>
                    <DropdownItemText>
                        Profile
                    </DropdownItemText>
                </DropdownItem> */}
            </Dropdown>
        </Main>
    );
};

export { AvatarGroup };