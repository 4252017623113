import { theme } from '@theme';
import styled from 'styled-components';

const { shadow, color, border, typography, zIndex } = theme;

interface HelperTextProps {
    error?: boolean;
}

interface ButtonProps {
    backgroundColor: string;
    color: string;
}

const maxContent = (max: number) => `
    max-width: ${max}px;
    width: 100%;
    margin: 0 auto;
`;

export const Main = styled.div``;

interface BoxContactProps {
    onPage: boolean;
}

export const BoxContact = styled.div<BoxContactProps>`
    border: .0625rem solid ${color.grey[50]};
    box-shadow: ${shadow.default};
    background-color: ${color.common.white};
    padding: 3.125rem 1.875rem 1.25rem 1.875rem;
    border-radius: ${border.default};
    position: relative;
    height: 100%;
    max-width: ${props => props.onPage ? '826px' : '100%'};
`;

export const ContactHeadline = styled.h1`
    font-size: 26px;
    font-weight: ${typography.fontWeightSemiBold};
    color: ${color.grey[900]};
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.25rem;

    & > img {
        margin-left: .75rem;
        width: auto;
        display: inline-block;
    }
`;

export const ContactSubTitle = styled.h2`
    font-size: 14px;
    font-weight: ${typography.fontWeightRegular};
    color: ${color.grey[900]};
`;

export const Form = styled.form`
    margin-top: 1.6875rem;
`;

export const WrapSubmit = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const SendButton = styled.button<ButtonProps>`
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    font-size: 16px;
    font-weight: ${typography.fontWeightRegular};
    padding: 12px 58px;
    border: 1px solid ${props => props.backgroundColor};
    border-radius: ${border.input};
    text-transform: uppercase;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }
`;

export const HelperText = styled.span<HelperTextProps>`
    font-size: 11px;
    font-weight: ${typography.fontWeightMedium};
    color: ${props => props.error ? color.red[900] : color.grey[900]};
`;

export const FormGroup = styled.div`
    margin-bottom: 15px;

    & > input,
    & > textarea
     {
        font-size: 14px;
        font-weight: ${typography.fontWeightRegular};
        color: ${color.grey[900]};
        border: .0625rem solid ${color.grey[200]};
        border-radius: ${border.input};
        padding: .6563rem 1.25rem;
        width: 100%;
        resize: none;
        
        &::placeholder {
            color: ${color.grey[700]};
            opacity: 1;
        }

    }
`;