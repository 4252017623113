import styled from "styled-components";
import {theme} from '@theme';

export const Main = styled.div`
    padding: 0.9375rem;
    margin: 1.875rem 0;
    line-height: 1.4;
    background-color: ${theme.color.common.white};
    color: ${theme.color.grey[800]};
    & > h1 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    & > p {
        font-size: 14px;
        font-weight: 200;
        margin-bottom: 10px;
    }

    & > h2 {
        font-weight: 600;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    & > ul {
        margin-bottom: 10px;
        padding-left: 30px;
        & > li {
            font-size: 14px;
            font-weight: 200;
            list-style: disc;
        }
    }
`;