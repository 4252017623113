import styled from "styled-components";
import { theme } from '@theme';

export const Main = styled.div`
    padding-top: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    & > a {
        text-decoration: none;
    }

`;

interface ButtonProps {
    bgColor: string;
    color: string;
}

export const Button = styled.button<ButtonProps>`
    cursor: pointer;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    display: flex;
    align-items: center;
    padding: 4px;
    border: none;
    border-radius: ${theme.border.default};
`;

export const ArrowDiv = styled.div`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.color.common.white};
    border-radius: ${theme.border.default};
`;

export const Text = styled.span`
    font-size: 14px;
    padding: 0 26px;
`;

export const Link = styled.div`
`;