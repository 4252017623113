import React from "react";
import {Main, WarningTitle, WarningSubtitle} from './styles';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

const Unpublished = () => {

    return (
        <Main>
            <I
                className="warning-icon"
                icon={['fal', 'info-circle']}
                style={{ height: '6.5rem' }}/>
            <WarningTitle>
                Este site ainda não foi publicado
            </WarningTitle>
            <WarningSubtitle>
                Tente nos visitar novamente mais tarde.
            </WarningSubtitle>
        </Main>
    )
}

export {Unpublished};