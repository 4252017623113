import React from 'react';

import MainIcon from '@img/mail-icon.png';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import $ from "jquery";
import "jquery-mask-plugin";
import { toast } from 'react-toastify';

import { location } from '@utils'
import { CONSTANTS } from '../../../../constants';

import {
  Grid,
} from '@design-system';

import {
  BoxContact,
  ContactHeadline,
  ContactSubTitle,
  Form,
  FormGroup,
  WrapSubmit,
  SendButton,
  HelperText,
} from './styles';
import { useParams } from 'react-router-dom';
import { useCampaign } from '@hooks';

export const formSchema = Yup.object().shape({
  userName: Yup.string()
    .required('Digite seu nome'),
  userEmail: Yup.string().email('Digite um email válido')
    .required('Digite seu e-mail'),
  phone: Yup.string()
    .required('Digite seu número de telefone'),
  subject: Yup.string()
    .required('Digite o assunto'),
  message: Yup.string()
    .required('Digite uma mensagem'),

});

export interface FormInputs {
  userName: string;
  userEmail: string;
  phone: string;
  subject: string;
  message: string;
}

interface ContactFormProps {
  onPage: boolean;
  backgroundColorButton: string;
  fontColorButton: string;
}

const toastConfig = {
  position: "bottom-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

const ContactForm: React.FC<ContactFormProps> =
  ({ backgroundColorButton, fontColorButton, onPage }: ContactFormProps) => {
    const { contactForm } = useCampaign();
    const getSubdomain = location.getSubdomain();
    const getFirstPathname = location.getFirstPathname();

    const { campaignPath } = useParams();
    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormInputs>({
      resolver: yupResolver(formSchema),
      mode: "onBlur",
    });

    const [isLoadingResponse, setIsLoadingResponse] = React.useState(false)

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
      setIsLoadingResponse(true)
      const response = await contactForm({
        websiteAliasCompany: getSubdomain === CONSTANTS.CAMPAIGN.LOCALHOST_DOMAIN
          ? CONSTANTS.CAMPAIGN.SUBDOMAIN : getSubdomain as string,
        websiteAliasProgram: getSubdomain === CONSTANTS.CAMPAIGN.LOCALHOST_DOMAIN
          ? CONSTANTS.PROGRAM_CODE : getFirstPathname as string,
        websiteAliasCampaign: campaignPath,
        data,
      })

      setIsLoadingResponse(false)
      if (response?.error) {
        toast(response && response.response.message, {
          ...toastConfig as any,
          type: "error",
        });
      } else {
        reset()
        toast(response && response.response.message, {
          ...toastConfig as any,
          type: "success",
          onClose: () => { }
        });
      }
    };

    React.useEffect(() => {
      $(".phone_with_ddd").mask("(00) 00000-0000");
    }, []);

    return (
      <React.Fragment>
          <BoxContact onPage className='boxForm'>
            <ContactHeadline>
              Dúvidas? Fale conosco <img src={MainIcon} alt="fale conosco icon" />
            </ContactHeadline>
            <ContactSubTitle>
              Envie-nos uma mensagem e te retornaremos o mais breve possível!
            </ContactSubTitle>

            <Form className='contactForm' onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item md={6}>
                  <FormGroup>
                    <input type='text' placeholder="Nome" {...register("userName", { required: true })} />
                    {errors.userName && (
                      <HelperText error>{errors.userName.message}</HelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item md={6}>
                  <FormGroup>
                    <input type='email' placeholder="Email"  {...register("userEmail", { required: true })} />
                    {errors.userEmail && (
                      <HelperText error>{errors.userEmail.message}</HelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item md={12}>
                  <FormGroup>
                    <input type='text' className='phone_with_ddd' placeholder="Telefone" {...register("phone", { required: true })} />
                    {errors.phone && (
                      <HelperText error>{errors.phone.message}</HelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item md={12}>
                  <FormGroup>
                    <input type='text' placeholder="Assunto" {...register("subject", { required: true })} />
                    {errors.subject && (
                      <HelperText error>{errors.subject.message}</HelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item md={12}>
                  <FormGroup>
                    <textarea placeholder="Mensagem" {...register("message", { required: true })} />
                    {errors.message && (
                      <HelperText error>{errors.message.message}</HelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item md={12}>
                  <WrapSubmit className='buttonWrap'>
                    <SendButton
                      className='sendButton'
                      backgroundColor={backgroundColorButton}
                      color={fontColorButton}
                      disabled={isLoadingResponse}
                      type='submit'>
                      {isLoadingResponse ? 'Carregando...' : 'Enviar'}
                    </SendButton>
                  </WrapSubmit>
                </Grid>
              </Grid>
            </Form>
          </BoxContact>
      </React.Fragment >
    );
  }

export { ContactForm };